import lightLogo from "../dist/assets/img/light-logo.svg";
import darkLogo from "../dist/assets/img/dark-logo.svg";

const logoMappings = {
    light: lightLogo,
    dark: lightLogo,
    cupcake: lightLogo,
    bumblebee: lightLogo,
    emerald: lightLogo,
    corporate: lightLogo,
    synthwave: lightLogo,
    retro: lightLogo,
    cyberpunk: lightLogo,
    valentine: darkLogo,
    halloween: lightLogo,
    garden: lightLogo,
    forest: lightLogo,
    aqua: darkLogo,
    lofi: lightLogo,
    pastel: darkLogo,
    fantasy: lightLogo,
    wireframe: darkLogo,
    black: lightLogo,
    luxury: lightLogo,
    dracula: lightLogo,
    cmyk: lightLogo,
    autumn: darkLogo,
    business: lightLogo,
    acid: lightLogo,
    lemonade: lightLogo,
    night: lightLogo,
    coffee: lightLogo,
    winter: lightLogo,
    dim: lightLogo,
    nord: lightLogo,
    sunset: lightLogo,
};

export default logoMappings;
