import React, {useContext, useState} from 'react';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {HOME_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE} from "../utils/consts";
import LogoCodybara from "../components/LogoCodybara";
import {login, registration} from "../http/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import AuthError from "./AuthError";

const Auth = observer(() => {
    const {user} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = location.pathname === LOGIN_ROUTE

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    function togglePasswordVisibility(event) {
        setIsPasswordVisible((prevState) => !prevState);
        event.preventDefault();
    }


    const [loginValue, setLogin] = useState('')
    const [passwordValue, setPassword] = useState('')
    const [nameValue, setName] = useState('')


    const [errorMessage, setErrorMessage] = useState('');
    const loginRegex = /^[a-zA-Z]{3,}\d*$/;
    const nameRegex = /^[А-ЯЁ][а-яё]+\s[А-ЯЁ][а-яё]+$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;

    const savedTheme = localStorage.getItem('currentTheme');
    const currentTheme = savedTheme || 'nord'; // Если в localStorage нет сохраненной темы, используйте значение по умолчанию

    const authUser = async () => {
        try {
            let data;
            if (isLogin) {
                data = await login(loginValue, passwordValue)
            } else {
                data = await registration(loginValue, nameValue, passwordValue)
            }
            user.setUser(data)
            user.setIsAuth(true)
            window.location.href = HOME_ROUTE;
            window.location.href = HOME_ROUTE;

        } catch (e) {
            setErrorMessage(e.response.data.message);
        }
    }

    const validateForm = (event) => {
        event.preventDefault();

        if (!loginRegex.test(loginValue)) {
            setErrorMessage('Логин должен содержать английские буквы и быть не менее 3 символов');
            return;
        }

        if (!isLogin && !nameRegex.test(nameValue)) {
            setErrorMessage('Ты написал неправильное имя или фамилию. Пример: Иван Иванов');
            return;
        }

        if (passwordValue.length < 6 || !passwordRegex.test(passwordValue)) {
            setErrorMessage('Пароль должен содержать не менее 6 символов, включая буквы и цифры');
            return;
        }

        authUser();
    };

    return (
        <div className="bg-neutral-content overflow-y-scroll h-full lg:h-fit">
            <LogoCodybara currentTheme={currentTheme} />
            <div className="m-auto flex flex-col justify-center h-screen w-4/5 md:w-2/4 my-10 lg:my-0">
                <h2 className="text-3xl mb-3">{isLogin ? 'Авторизация' : 'Регистрация'}</h2>
                <form onSubmit={validateForm} className={"text-accent-content"}>
                    <label className="form-control w-full mb-2">
                        <div className="label">
                            <span className="text-xl">Ваш логин</span>
                        </div>
                        <input
                            type="text" placeholder="Напишите тут логин"
                            value={loginValue}
                            onChange={e => setLogin(e.target.value)}
                            className="input input-bordered w-full"
                            autoComplete="username"
                        />
                    </label>
                    {isLogin ? <div></div> : <label className="form-control w-full mb-2">
                        <div className="label">
                            <span className="text-xl">Ваше имя и фамилия</span>
                        </div>
                        <input
                            type="text" placeholder="Напишите тут имя и фамилию"
                            value={nameValue}
                            onChange={e => setName(e.target.value)}
                            className="input input-bordered w-full" />
                    </label>}
                    <label className="form-control w-full mb-6 relative">
                        <div className="label">
                            <span className="text-xl">Пароль</span>
                        </div>
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            value={passwordValue}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="Напишите тут пароль"
                            className="input input-bordered w-full"
                            autoComplete={isLogin ? "current-password" : "new-password"}
                        />
                        <button
                            className="absolute bottom-3.5 right-0 flex items-center px-4 text-gray-600"
                            onClick={togglePasswordVisibility}
                        >
                            {isPasswordVisible ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            )}
                        </button>
                    </label>
                    <button
                        className="btn btn-neutral w-full"
                        type="submit"
                    >{isLogin ? 'Войти' : 'Регистрация'}</button>
                    {isLogin
                        ?
                        <div className="flex items-center w-full justify-center">
                            <div className={""}>Нет аккаунта?</div>
                            <div className="btn btn-link">
                                <NavLink to={REGISTRATION_ROUTE}>Регистрация</NavLink>
                            </div>
                        </div>

                        :
                        <div className="flex items-center w-full justify-center">
                            <div>Есть аккаунт?</div>
                            <div className="btn btn-link">
                                <NavLink to={LOGIN_ROUTE}>Войти в аккаунт</NavLink>
                            </div>
                        </div>
                    }

                </form>
                {errorMessage && <AuthError error={errorMessage} />}
            </div>
        </div>

    );
});

export default Auth;
