import React, {useEffect, useRef, useState} from 'react';
import {createTask} from "../../../http/courseAPI";
import {Store} from "react-notifications-component";
import {getAllModules} from "../../../http/createAPI";

const AddTask = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [moduleId, setModuleId] = useState(0)
    const [taskId, setTaskId] = useState(0)
    const [column, setColumn] = useState('')

    const [modules, setModules] = useState([]);
    const [dropdownTitle, setDropdownTitle] = useState('Выберите модуль');
    const dropdownRef = useRef(null);

    useEffect(() => {
        getAllModules().then(data => {
            setModules(data);
        }).catch(error => {
            console.error("Ошибка при получении модулей:", error);
        });
    }, []);

    const selectModule = (module) => {
        setModuleId(module.id);
        setDropdownTitle(module.title);
        if (dropdownRef.current) {
            dropdownRef.current.classList.remove('open');
        }
    };

    const toggleDropdown = () => {
        if (dropdownRef.current) {
            dropdownRef.current.classList.toggle('open');
        }
    };

    const closeModal = () => {
        document.getElementById('modal_add_task').close();
    }

    const addNewTask = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('task_id', taskId);
        formData.append('teacher_approved', false);
        formData.append('task_state', column);
        formData.append('courseModuleId', moduleId);

        createTask(formData).then(data => {
            closeModal();
            Store.addNotification({
                title: "Готово!",
                message: "Задание успешно добавлено",
                type: "success",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }).catch(error => {
            closeModal();
            Store.addNotification({
                title: "Ошибка!",
                message: "Произошла ошибка при добавлении задания",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        });
    }
    return (
        <div>
            <div>
                <button
                    className="btn btn-outline w-fit"
                    onClick={()=>document.getElementById('modal_add_task').showModal()}
                >Добавить</button>
                <dialog id="modal_add_task" className="modal">
                    <div className="modal-box">
                        <div className="flex items-center justify-between mb-5">
                            <h3 className="font-bold text-2xl">Добавление нового задания</h3>
                            <kbd className="kbd">ESC</kbd>
                        </div>
                        <hr/>
                        <form className="flex flex-col mt-5" onSubmit={addNewTask}>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Введите название задания</span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Название задания..."
                                    className="input input-bordered w-full"
                                    value={title}
                                    onChange={e => {setTitle(e.target.value)}}
                                />
                                <div  className="mt-2.5 badge badge-outline">Название задания должно быть уникальным</div>
                            </label>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Введите описание задания</span>
                                </div>
                                <textarea
                                    className="textarea textarea-bordered h-24"
                                    placeholder="Описание задания..."
                                    value={description}
                                    onChange={e => {setDescription(e.target.value)}}
                                ></textarea>
                            </label>
                            <div className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Выберите модуль</span>
                                </div>
                                <div className="dropdown" ref={dropdownRef}>
                                    <div tabIndex={0} role="button" className="btn m-1" onClick={toggleDropdown}>{dropdownTitle}</div>
                                    <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full">
                                        {modules.map(module => (
                                            <li key={module.id} onClick={() => selectModule(module)}>
                                                <a>{module.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Введите ID задания</span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="ID задания..."
                                    className="input input-bordered w-full"
                                    value={taskId}
                                    onChange={e => {setTaskId(e.target.value)}}
                                />
                            </label>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">В какой колонке должно стоять задание</span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="todo, doing, done"
                                    className="input input-bordered w-full"
                                    value={column}
                                    onChange={e => {setColumn(e.target.value)}}
                                />
                            </label>
                            <button className="btn btn-primary mt-5" type='submit'>Добавить задание</button>
                        </form>

                    </div>
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                </dialog>
            </div>
        </div>
    );
};

export default AddTask;