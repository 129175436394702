import React, {useContext, useEffect, useState} from 'react';
import NavBar from "../components/NavBar";
import RootLayout from "../components/RootLayout";
import ModuleTask from "../components/ModuleTask";
import {useParams} from "react-router-dom";
import { Context } from "../index";
import AccessDeniedAlert from "../components/AccessDeniedAlert";
import {getModuleProgress} from "../http/userAPI";


const ModulePage = () => {
    const { user } = useContext(Context);
    const { courseId, moduleId } = useParams();
    const { moduleProgressStore } = useContext(Context);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchModuleProgress = async () => {
            try {
                const numericModuleId = parseInt(moduleId, 10); // Преобразование строки в число
                const progressData = await getModuleProgress(user.user.id, [numericModuleId]);
                moduleProgressStore.setModuleProgress(progressData);
            } catch (error) {
                console.error('Error fetching module progress:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (!moduleProgressStore.isModuleProgressLoaded(parseInt(moduleId, 10))) {
            fetchModuleProgress();
        } else {
            setIsLoading(false);
        }
    }, [moduleId, moduleProgressStore, user.user.id]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen w-screen">
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        )
    }

    const isModuleOpen = moduleProgressStore.isModuleOpen(moduleId);

    return (
        <RootLayout>
            <NavBar />
            <div>
                {isModuleOpen ? <ModuleTask courseId={courseId} moduleId={moduleId}/> : <AccessDeniedAlert/>}
            </div>
        </RootLayout>
    );
};

export default ModulePage;