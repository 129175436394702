import img1 from '../dist/assets/img/course-test-1.png'
import {makeAutoObservable} from "mobx";

export default class PassedCourseStore {
    constructor() {
        this._passed_courses = []
        makeAutoObservable(this)
    }

    setCourse(passed_courses) {
        this._passed_courses = passed_courses
    }

    get currentCourse() {
        return this._passed_courses
    }
}