import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../index';
import { addUserPassedCourses } from '../../../http/courseAPI';
import { Store } from 'react-notifications-component';
import { getAllCourses, getAllStudents } from '../../../http/createAPI';

const AddStudentPassedCourse = () => {
    const [userID, setUserID] = useState(0);
    const [courseID, setCourseID] = useState(0);
    const [users, setUsers] = useState([]);
    const [courses, setCourses] = useState([]);

    const { user } = useContext(Context);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersData = await getAllStudents();
                setUsers(usersData);

                const coursesData = await getAllCourses();
                setCourses(coursesData);
            } catch (error) {
                console.error('Error fetching users or courses:', error);
            }
        };

        fetchData();
    }, []);

    const handleAddStudentPassedCourse = async (e) => {
        e.preventDefault();
        try {
            if (user.user.role === 'ADMIN' || user.user.role === 'ADMINISTRATOR') {
                await addUserPassedCourses(userID, courseID);
                Store.addNotification({
                    title: 'Получилось!',
                    message: 'Пользователю успешно добавлен прошедший курс',
                    type: 'success',
                    insert: 'top',
                    container: 'bottom-center',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
                document.getElementById('modal_add_student_passed_course').close();
            } else {
                alert(
                    'Увы, но вы не администратор. Если это ошибка, свяжитесь с разработчиками'
                );
            }
        } catch (error) {
            Store.addNotification({
                title: 'Упс, что-то пошло не так!',
                message: 'Пользователя не получилось добавить на прошедший курс',
                type: 'danger',
                insert: 'top',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
            document.getElementById('modal_add_student_passed_course').close();
        }
    };

    return (
        <div>
            <button
                className="btn btn-outline w-fit text-accent"
                onClick={() =>
                    document.getElementById('modal_add_student_passed_course').showModal()
                }
            >
                Добавить
            </button>
            <dialog
                id="modal_add_student_passed_course"
                className="modal"
            >
                <div className="modal-box">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="font-bold text-2xl text-accent">
                            Добавить ученику прошедший курс
                        </h3>
                        <kbd className="kbd">ESC</kbd>
                    </div>
                    <hr />
                    <form
                        className="flex flex-col mt-5"
                        onSubmit={handleAddStudentPassedCourse}
                    >
                        <label className="form-control w-full">
                            <div className="label">
                <span className="label-text text-lg">
                  Выберите ученика
                </span>
                            </div>
                            <select
                                className="select select-bordered w-full"
                                value={userID}
                                onChange={(e) => setUserID(e.target.value)}
                            >
                                <option disabled value="">
                                    Выберите ученика
                                </option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Выберите курс</span>
                            </div>
                            <select
                                className="select select-bordered w-full"
                                value={courseID}
                                onChange={(e) => setCourseID(e.target.value)}
                            >
                                <option disabled value="">
                                    Выберите курс
                                </option>
                                {courses.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <button
                            className="btn btn-primary mt-5"
                            type="submit"
                        >
                            Добавить ученику прошедший курс
                        </button>
                    </form>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    );
};

export default AddStudentPassedCourse;
