import React, {useContext, useEffect, useState} from 'react'
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import './dist/output.css'
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check} from "./http/userAPI";

const App = observer(() => {
    const {user} = useContext(Context)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            user.setIsAuth(false);
            setLoading(false);
        } else {
            check()
                .then(data => {
                    user.setUser(data);
                    user.setIsAuth(true);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem('token'); // Удаляем недействительный токен
                        user.setIsAuth(false);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen w-screen bg-neutral">
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        )
    }

    return (
    <BrowserRouter>
        <AppRouter />
    </BrowserRouter>
  );
})

export default App;
