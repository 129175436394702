import {makeAutoObservable} from "mobx";

export default class AchievementsStore {
    constructor() {
        this._achievements = [
            // {id:1, title:"Ачивка 1", description:"Знакомство с курсом 1", logo:"https://sun9-17.userapi.com/impg/H4rxuif7m4_W-TZqQeYx8vukqVi8XoHLUqO6pQ/dFioOMHDVuE.jpg?size=45x45&quality=95&sign=61958b9d08899f30b65a388cae4398b7&type=album", background:"https://sun9-27.userapi.com/impg/yzgLtnqE_KCbKh3jYcG_aEi5HixvfXiRVPZeBg/HfS8SK8mhNs.jpg?size=213x213&quality=95&sign=f46955b9c03c17e65c428cd15320df32&type=album"},
            // {id:2, title:"Ачивка 2", description:"Поучавствовать в ивенте посвященному Новому 2024 году", logo:"https://sun9-76.userapi.com/impg/H6UwMAtfxFT_gqIwdh2Mqfoo_ZMn8K6SgEDo7w/UWDgkQ8-h5s.jpg?size=45x45&quality=95&sign=0671ba1e77034364f10abe3d9b3caa1d&type=album", background:"https://sun9-27.userapi.com/impg/yzgLtnqE_KCbKh3jYcG_aEi5HixvfXiRVPZeBg/HfS8SK8mhNs.jpg?size=213x213&quality=95&sign=f46955b9c03c17e65c428cd15320df32&type=album"},
            // {id:3, title:"Ачивка 2", description:"Знакомство с курсом 1", logo:"https://sun9-17.userapi.com/impg/H4rxuif7m4_W-TZqQeYx8vukqVi8XoHLUqO6pQ/dFioOMHDVuE.jpg?size=45x45&quality=95&sign=61958b9d08899f30b65a388cae4398b7&type=album", background:"https://sun9-27.userapi.com/impg/yzgLtnqE_KCbKh3jYcG_aEi5HixvfXiRVPZeBg/HfS8SK8mhNs.jpg?size=213x213&quality=95&sign=f46955b9c03c17e65c428cd15320df32&type=album"},
            // {id:4, title:"Ачивка 2", description:"Знакомство с курсом 1", logo:"https://sun9-17.userapi.com/impg/H4rxuif7m4_W-TZqQeYx8vukqVi8XoHLUqO6pQ/dFioOMHDVuE.jpg?size=45x45&quality=95&sign=61958b9d08899f30b65a388cae4398b7&type=album", background:"https://sun9-27.userapi.com/impg/yzgLtnqE_KCbKh3jYcG_aEi5HixvfXiRVPZeBg/HfS8SK8mhNs.jpg?size=213x213&quality=95&sign=f46955b9c03c17e65c428cd15320df32&type=album"},
            // {id:5, title:"Ачивка 2", description:"Знакомство с курсом 1", logo:"https://sun9-17.userapi.com/impg/H4rxuif7m4_W-TZqQeYx8vukqVi8XoHLUqO6pQ/dFioOMHDVuE.jpg?size=45x45&quality=95&sign=61958b9d08899f30b65a388cae4398b7&type=album", background:"https://sun9-27.userapi.com/impg/yzgLtnqE_KCbKh3jYcG_aEi5HixvfXiRVPZeBg/HfS8SK8mhNs.jpg?size=213x213&quality=95&sign=f46955b9c03c17e65c428cd15320df32&type=album"},
            // {id:6, title:"Ачивка 2", description:"Знакомство с курсом 1", logo:"https://sun9-17.userapi.com/impg/H4rxuif7m4_W-TZqQeYx8vukqVi8XoHLUqO6pQ/dFioOMHDVuE.jpg?size=45x45&quality=95&sign=61958b9d08899f30b65a388cae4398b7&type=album", background:"https://sun9-27.userapi.com/impg/yzgLtnqE_KCbKh3jYcG_aEi5HixvfXiRVPZeBg/HfS8SK8mhNs.jpg?size=213x213&quality=95&sign=f46955b9c03c17e65c428cd15320df32&type=album"},
        ]
        makeAutoObservable(this)
    }

    setCourse(achievements) {
        this._achievements = achievements
    }

    get currentAchievements() {
        return this._achievements
    }
}