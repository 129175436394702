import React, {useState} from 'react';
import {createCourse} from "../../../http/courseAPI";
import {Store} from "react-notifications-component";

const AddCourse = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [background, setBackground] = useState(null)
    const [tags, setTags] = useState('')
    const [duration, setDuration] = useState('')
    const [countLessons, setCountLessons] = useState('')
    const [countTasks, setCountTasks] = useState('')
    const [countProjects, setCountProjects] = useState('')
    const [logo, setLogo] = useState(null)

    const selectBackgroundFile = e => {
        setBackground(e.target.files[0])
    }

    const selectLogoFile = e => {
        setLogo(e.target.files[0])
    }

    const addCourse = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('title', title)
        formData.append('description', description)
        formData.append('background', background)
        formData.append('tags', tags)
        formData.append('duration', duration)
        formData.append('countLessons', countLessons)
        formData.append('countTasks', countTasks)
        formData.append('countProjects', countProjects)
        formData.append('logo', logo)
        createCourse(formData).then(data => {
            document.getElementById('modal_add_course').close();
            Store.addNotification({
                title: "Готово!",
                message: "Курс успешно добавлен.",
                type: "success",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }).catch(error => {
            document.getElementById('modal_add_course').close();
            Store.addNotification({
                title: "Ошибка!",
                message: "Произошла ошибка при добавлении курса.",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        });
    }

    return (
        <div>
            <button
                className="btn btn-outline w-fit border-white text-white hover:bg-white hover:text-inherit hover:border-white"
                onClick={()=>document.getElementById('modal_add_course').showModal()}
            >Добавить</button>
            <dialog id="modal_add_course" className="modal">
                <div className="modal-box">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="font-bold text-2xl">Добавление нового курса</h3>
                        <kbd className="kbd">ESC</kbd>
                    </div>
                    <hr/>
                    <form className="flex flex-col mt-5" onSubmit={addCourse}>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите название курса</span>
                            </div>
                            <input
                                type="text"
                                placeholder="Название курса..."
                                className="input input-bordered w-full"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                            />
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите описание курса</span>
                            </div>
                            <textarea
                                className="textarea textarea-bordered h-24"
                                placeholder="Описание курса..."
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            ></textarea>
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите теги курса</span>
                            </div>
                            <textarea
                                className="textarea textarea-bordered h-24"
                                placeholder="Теги курса..."
                                value={tags}
                                onChange={e => setTags(e.target.value)}
                            ></textarea>
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите длительность курса</span>
                            </div>
                            <input
                                type="text"
                                placeholder="Длительность курса..."
                                className="input input-bordered w-full"
                                value={duration}
                                onChange={e => setDuration(e.target.value)}
                            />
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите количество уроков на курсе</span>
                            </div>
                            <input
                                type="number"
                                placeholder="Количество уроков на курсе..."
                                className="input input-bordered w-full"
                                value={countLessons}
                                onChange={e => setCountLessons(e.target.value)}
                            />
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите количество заданий на курсе</span>
                            </div>
                            <input
                                type="number"
                                placeholder="Количество заданий на курсе..."
                                className="input input-bordered w-full"
                                value={countTasks}
                                onChange={e => setCountTasks(e.target.value)}
                            />
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите количество проектов на курсе</span>
                            </div>
                            <input
                                type="number"
                                placeholder="Количество проектов на курсе..."
                                className="input input-bordered w-full"
                                value={countProjects}
                                onChange={e => setCountProjects(e.target.value)}
                            />
                        </label>

                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Загрузите баннер курса</span>
                            </div>
                            <input type="file" onChange={selectBackgroundFile} className="file-input w-full" />
                        </label>

                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Загрузите логотип курса</span>
                            </div>
                            <input type="file" onChange={selectLogoFile} className="file-input w-full" />
                        </label>

                        <button className="btn btn-primary mt-5" type="submit">Добавить курс</button>
                    </form>

                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    );
};

export default AddCourse;