import {motion} from "framer-motion";
import React, {useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {FiHome} from "react-icons/fi";
import {FaDiscord, FaRegFolder, FaYoutube} from "react-icons/fa";
import {useMediaQuery} from "react-responsive";
import {MdMenu} from "react-icons/md";
import {
    ADMIN_ROUTE,
    ADMINISTRATOR_ROUTE,
    COURSE_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE,
    METHODIST_ROUT,
    TEACHER_ROUT
} from "../utils/consts";
import LogoCodybara from "./LogoCodybara";
import {Context} from "../index";
import {getUser} from "../http/userAPI";
import {LuCalendarCheck, LuFile, LuRocket, LuUserCog} from "react-icons/lu";

const SideBar = () => {
    const {user} = useContext(Context);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isTeacher, setIsTeacher] = useState(false);
    const [isMethodist, setIsMethodist] = useState(false);
    const [isAdministrator, setIsAdministrator] = useState(false);

    const savedTheme = localStorage.getItem('currentTheme');
    const currentTheme = savedTheme || 'default'; // Если в localStorage нет сохраненной темы, используйте значение по умолчанию


    useEffect(() => {
        getUser().then(data => {
            user.setUser(data);
            setIsAdmin(data.role === 'ADMIN');
            setIsTeacher(data.role === "TEACHER" || data.role === "ADMIN");
            setIsMethodist(data.role === 'METHODIST' || data.role === "ADMIN");
            setIsAdministrator(data.role === 'ADMINISTRATOR' || data.role === "ADMIN");
        });
    }, [user.setUser]);

    let isTab = useMediaQuery({query:"(max-width: 768px)"});
    const [isOpen, setIsOpen] = useState(!isTab);
    const Sidebar_animation = isTab
        ?
        {
            open: {
                x : 0,
                width: "16rem",
                transition: {
                    damping: 40,
                },
            },
            closed: {
                x : -250,
                width:0,
                transition: {
                    damping: 40,
                    delay: 0.15,
                },
            },
        }
        :
        {
            open: {
                width: "16rem",
                transition: {
                    damping: 40,
                },
            },
            closed: {
                width: "4rem",
                transition: {
                    damping: 40,
                },
            },
        };

    useEffect(() => {
        if (isTab) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    }, [isTab]);


    return (
        <div>
            <div
                onClick={() => setIsOpen(false)}
                className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50
                ${isOpen ? 'block' : 'hidden'}`}
            ></div>
            <motion.div
                variants={Sidebar_animation}
                initial={{x: isTab ? -250 : 0}}
                animate={isOpen ? "open" : "closed"}
                className="bg-neutral shadow-xl z-[999] w-[16rem] max-w-[16rem] h-screen overflow-hidden md:relative fixed ">

                <LogoCodybara currentTheme={currentTheme} />

                <div className="h-full relative">
                    <ul className="whitespace-pre text-neutral-content px-2.5 py-5 flex flex-col gap-1 font-medium overflow-x-hidden">
                        <li>
                            <NavLink to={HOME_ROUTE} className={"navbar-link "}>
                                <FiHome size={23}/>
                                <div>Главная</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={COURSE_ROUTE} className={"navbar-link "}>
                                <FaRegFolder size={23}/>
                                <div>Обучение</div>
                            </NavLink>
                        </li>
                        { isMethodist  ?
                            <li>
                                <NavLink to={METHODIST_ROUT} className={"navbar-link "}>
                                    <LuFile size={23}/>
                                    <div>Методисту</div>
                                </NavLink>
                            </li>
                            : ''
                        }
                        { isTeacher  ?
                            <li>
                                <NavLink to={TEACHER_ROUT} className={"navbar-link "}>
                                    <LuCalendarCheck size={23}/>
                                    <div>Учителю</div>
                                </NavLink>
                            </li>
                            : ''
                        }
                        { isAdministrator  ?
                            <li>
                                <NavLink to={ADMINISTRATOR_ROUTE} className={"navbar-link "}>
                                    <LuUserCog size={23}/>
                                    <div>Администратору</div>
                                </NavLink>
                            </li>
                            : ''
                        }
                        { isAdmin  ?
                            <li>
                                <NavLink to={ADMIN_ROUTE} className={"navbar-link "}>
                                    <LuRocket size={23}/>
                                    <div>Админу</div>
                                </NavLink>
                            </li>
                            : ''
                        }
                    </ul>
                </div>

                <div className="flex px-2.5 items-center gap-2.5 absolute bottom-2 left-0 text-neutral-content">
                    <a href="https://discord.gg/vBtsxD4sKh" target="_blank"><FaDiscord size={35}/></a>
                    <a href="https://www.youtube.com/@codybara" target="_blank"><FaYoutube size={35}/></a>
                </div>

            </motion.div>
            <div className="p-3 z-[998] absolute bottom-2 left-2 rounded-full bg-primary text-neutral-content border-neutral-content border md:hidden "
                 onClick={() => setIsOpen(true)}>
                <MdMenu size={25}></MdMenu>
            </div>
        </div>
    )
};

export default SideBar;