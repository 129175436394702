import {makeAutoObservable} from "mobx";

export default class TaskStore {
    constructor() {
        this._tasks = []
        this._selectedModuleId = null;

        makeAutoObservable(this)
    }

    setTasks(tasks) {
        this._tasks = tasks
    }

    get currentTask() {
        return this._tasks
    }

    get selectedModuleId() {
        return this._selectedModuleId;
    }

    set selectedModuleId(moduleId) {
        this._selectedModuleId = moduleId;
    }

    getTaskById(taskId) {
        for (const board of this._tasks) {
            const task = board.items.find(item => item.taskId === taskId);
            if (task) {
                return task;
            }
        }
        return null;
    }

    saveSelectedModuleIdToLocalStorage() {
        localStorage.setItem('selectedModuleId', JSON.stringify(this._selectedModuleId));
    }

    // Восстановление selectedModuleId из Local Storage
    restoreSelectedModuleIdFromLocalStorage() {
        const storedSelectedModuleId = localStorage.getItem('selectedModuleId');
        if (storedSelectedModuleId) {
            this._selectedModuleId = JSON.parse(storedSelectedModuleId);
        }
    }
}