import React, { useContext, useEffect, useState } from 'react';
import NavBar from "../components/NavBar";
import RootLayout from "../components/RootLayout";
import AccessDeniedAlert from "../components/AccessDeniedAlert"; // Убедитесь, что компонент импортирован
import { Context } from "../index";
import { useLocation, useNavigate } from "react-router-dom";
import { getModulesByCourseId } from "../http/courseAPI";
import { getModuleProgress } from "../http/userAPI";
import { observer } from "mobx-react-lite";
import moduleProgressStore from "../store/ModuleProgressStore";

const CoursePage = observer(() => {
    const { user } = useContext(Context);
    const { modules } = useContext(Context);
    const { tasks } = useContext(Context);
    const location = useLocation();
    const idCourse = location.pathname.split('/')[2];
    const navigate = useNavigate();

    const [moduleProgress, setModuleProgress] = useState({});
    const [accessDenied, setAccessDenied] = useState(false); // Состояние для отслеживания доступа

    useEffect(() => {
        tasks.restoreSelectedModuleIdFromLocalStorage();
        const fetchModules = async () => {
            try {
                const modulesData = await getModulesByCourseId(idCourse);
                modules.setModules(modulesData);

                const moduleIds = modulesData.map(module => module.id);

                try {
                    const progressData = await getModuleProgress(user.user.id, moduleIds);
                    const progressMap = progressData.reduce((map, item) => {
                        map[item.moduleId] = item;
                        return map;
                    }, {});

                    setModuleProgress(progressMap);
                    moduleProgressStore.setModuleProgress(progressData);
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        setAccessDenied(true);
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setAccessDenied(true);
                }
            }
        };
        fetchModules();
    }, []);

    const navigateToCourse = (moduleId) => {
        tasks.selectedModuleId = moduleId;
        tasks.saveSelectedModuleIdToLocalStorage();
        navigate(`/course/${idCourse}/modules/${moduleId}`);
    };

    return (
        <RootLayout>
            <NavBar />
            <div>
                {accessDenied ? (
                    <AccessDeniedAlert /> // Отображение компонента при отсутствии доступа
                ) : (
                    <div className="px-5 xl:px-0 overflow-y-scroll pb-5 h-[89vh] scrollbar-none">
                        <div className="mt-5 text-4xl lg:text-5xl font-semibold">Обучение</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
                            {modules.currentModule.filter(moduleValue => idCourse === moduleValue.courseId.toString()).map(filteredModule => {
                                const isModuleOpen = moduleProgress[filteredModule.id]?.isOpened || false;
                                return (
                                    <div
                                        className={`p-4 ${isModuleOpen ? 'bg-gradient' : 'bg-gray-600'} text-white rounded-lg flex flex-col justify-between bg-cover bg-center hover:cursor-pointer`}
                                        key={filteredModule.id}
                                        onClick={() => isModuleOpen && navigateToCourse(filteredModule.id)}
                                    >
                                        <div className="flex flex-col">
                                            <div className="text-2xl md:text-3xl lg:text-4xl my-4">{filteredModule.title}</div>
                                            <div>{filteredModule.description}</div>
                                            {!isModuleOpen && <div className="text-red-500">Модуль закрыт</div>}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </RootLayout>
    );
});

export default CoursePage;