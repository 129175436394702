import React, {useContext, useEffect} from 'react';
import RootLayout from "../components/RootLayout";
import NavBar from "../components/NavBar";
import {Context} from "../index";
import HomeAchievements from "../components/HomeAchievements";
import {observer} from "mobx-react-lite";
import {LuBookMarked, LuCopyCheck, LuFileCode} from "react-icons/lu";
import {useNavigate} from "react-router-dom";
import {COURSE_ROUTE} from "../utils/consts";
import EmptyCourseList from "../components/EmptyCourseList";
import {getUser} from "../http/userAPI";
import {getCurrentCourses, getPassedCourses} from "../http/courseAPI";
import {ReactNotifications} from "react-notifications-component";

const Home = observer(() => {
    const {user} = useContext(Context)
    const {course} = useContext(Context)
    const {passedCourse} = useContext(Context)

    const navigate = useNavigate()

    useEffect(() => {
        async function fetchData() {
            const userData = await getUser();
            user.setUser(userData);

            const currentCoursesData = await getCurrentCourses();
            const passedCoursesData = await getPassedCourses();

            const filteredCurrentCourses = currentCoursesData.filter(course =>
                !passedCoursesData.some(passedCourse => passedCourse.id === course.id)
            );

            course.setCourse(filteredCurrentCourses);
            passedCourse.setCourse(passedCoursesData);
        }
        fetchData();
    }, []);
    function navigateToCourse(courseId) {
        navigate(`${COURSE_ROUTE}/${courseId}`)
    }

    function getCourseWord(count) {
        count = Math.abs(count) % 100;
        const num = count % 10;
        if(count > 10 && count < 20) return 'курсов';
        if(num > 1 && num < 5) return 'курса';
        if(num === 1) return 'курс';
        return 'курсов';
    }

    function determineClass(courseCount) {
        if (courseCount === 0) {
            return "bg-primary";
        } else if (courseCount > 0 && courseCount <= 5) {
            return "bg-accent";
        } else {
            return "bg-gradient-courses";
        }
    }


    return (
        <div>
            <RootLayout>
                <ReactNotifications />
                <NavBar />
                <div className="mx-5 md:grid md:grid-cols-2 md:gap-5 lg:mx-0 mt-5 h-[86vh] xl:h-[89vh] overflow-y-scroll overflow-x-hidden scrollbar-none">
                    <div>
                        <div className="mb-5">
                            {Array(user.user).map(currentUser =>
                                <div key={currentUser.id} className="text-3xl lg:text-5xl font-bold">Привет, <span className="font-normal">{currentUser.name.split(' ')[0]} 👋</span></div>
                            )}
                        </div>
                        <div className="">Добро пожаловать в личный кабинет Кодибары! Приготовься и начинай выполнять задания курса. Конечно, если урок начался.</div>

                        <div className="mt-5">
                            <div className="text-xl lg:text-3xl font-bold">Текущие курсы</div>
                            <div className="mt-5">
                                {course.currentCourse.length === 0 ?
                                    <EmptyCourseList/>
                                :
                                    course.currentCourse.map(courseValue =>
                                        <div
                                            className="p-5 rounded-3xl mb-5 border border-primary"
                                            key={courseValue.id}
                                        >

                                            <div>
                                                <div className="flex items-start flex-col gap-5 mb-5 lg:flex-row">
                                                    <div className="rounded-full  bg-neutral p-3">
                                                        <img className="rounded-full w-[50px]" src={process.env.REACT_APP_API_URL + courseValue.logo} alt="Логотип курса"/>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <div className="text-xl font-semibold mb-5 md:text-lg lg:text-xl">{courseValue.title}</div>
                                                        <div className="grid grid-cols-2 gap-2.5 md:block lg:grid">
                                                            <div className="flex gap-2.5 items-center"><LuBookMarked />{courseValue.countLessons} уроков</div>
                                                            <div className="flex gap-2.5 items-center"><LuCopyCheck />{courseValue.countTasks} заданий</div>
                                                            <div className="flex gap-2.5 items-center"><LuFileCode />{courseValue.countProjects} проектов</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn btn-neutral w-full rounded-full" onClick={() => navigateToCourse(courseValue.id)}>Перейти на курс</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="p-5 bg-neutral rounded-3xl relative z-0">
                            <div>
                                <div className="bg-gradient h-[135px] rounded-3xl"></div>
                                <div className="bg-white absolute drop-shadow-md top-28 rounded-3xl h-[100px] w-[100px] flex items-center justify-center">
                                    <div className="w-full h-full">
                                        {user.user.avatar ? (
                                            <img className="h-[100px] rounded-3xl" src={process.env.REACT_APP_API_URL + 'profiles/' + user.user.avatar} alt=""/>
                                        ) : (
                                            <div className="bg-neutral text-neutral-content rounded-3xl h-full flex items-center justify-center">
                                                <span className="text-3xl">{user.user.name[0]}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="bg-base-100 p-5 rounded-3xl mt-5 lg:flex  lg:flex-row w-full lg:justify-between">
                                    <div className="mt-10">
                                        <div>
                                            <div className="font-semibold mb-2.5">{user.user.name}</div>
                                            {user.user.info !== null ?
                                                <div className="w-full">
                                                    {user.user.info}
                                                </div>
                                                :
                                                <div className="">
                                                    Это описание можно менять, его добавили тут просто для примера. Поменять описание можно кликнув на аватарку сверху :)
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className={`p-5 rounded-3xl flex flex-col items-center justify-center mt-5 lg:ml-5 ${determineClass(passedCourse.currentCourse.length)}`}>
                                        <div className="font-semibold text-neutral-content">Закончил</div>
                                        <div className="p-2 bg-neutral rounded-full w-12 text-center my-2">
                                            <div className="text-2xl text-neutral-content">{passedCourse.currentCourse.length}</div>
                                        </div>
                                        <div className={"text-neutral-content"}>{getCourseWord(passedCourse.currentCourse.length)}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="my-5">
                            <HomeAchievements/>
                        </div>
                    </div>

                </div>


            </RootLayout>
        </div>
    );
});

export default Home;