import React from 'react';
import logoMappings from './logoMappings';
import lightLogo from "../dist/assets/img/light-logo.svg";

const LogoCodybara = ({ currentTheme }) => {
    // Получите логотип из объекта сопоставления
    let logoSrc = logoMappings[currentTheme];

    if (!logoSrc) {
        logoSrc = lightLogo;
    }

    return (
        <div>
            <a href="https://codybara.ru/" className="flex items-center gap-3 border-b border-[#29374A33] py-3 mx-3">
                <img src={logoSrc} width={200} alt="Логотип Кодибары" />
            </a>
        </div>
    );
};

export default LogoCodybara;
