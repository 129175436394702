import React, {useContext, useEffect} from 'react';
import RootLayout from "../components/RootLayout";
import NavBar from "../components/NavBar";
import {Context} from "../index";
import CourseList from "../components/CourseList";
import {getCurrentCourses, getPassedCourses} from "../http/courseAPI";
import {getUser} from "../http/userAPI";
import {observer} from "mobx-react-lite";
import EmptyCourseList from "../components/EmptyCourseList";

const Education = observer(() => {
    const {course} = useContext(Context)
    const {passedCourse} = useContext(Context)

    useEffect(() => {
        async function fetchData() {
            const currentCoursesData = await getCurrentCourses();
            const passedCoursesData = await getPassedCourses();
            const filteredCurrentCourses = currentCoursesData.filter(course =>
                !passedCoursesData.some(passedCourse => passedCourse.id === course.id)
            );

            course.setCourse(filteredCurrentCourses);
            passedCourse.setCourse(passedCoursesData);
        }
        fetchData();
    }, []);

    return (
        <div>
            <RootLayout>
                <NavBar />

                <div className="h-[89vh] lg:h-[92vh] overflow-y-scroll scrollbar-none">
                    {course.currentCourse.length === 0 ?
                        <div className="mt-5 mx-5 md:mx-0 md:mr-5 lg:mr-0"><EmptyCourseList/></div>
                        :
                        <CourseList courseList={course.currentCourse} title="Текущие курсы"/>
                    }

                    {passedCourse.currentCourse.length === 0 ?
                        <div></div>
                        :
                        <CourseList courseList={passedCourse.currentCourse} title="Пройденные курсы"/>
                    }
                </div>



            </RootLayout>
        </div>
    );
});

export default Education;