import React, { useRef } from 'react';
import {useNavigate, useParams} from 'react-router-dom';

const ModuleTaskModal = ({ taskInfo, onOpenTask, onSubmitForReview }) => {
    const dialogRef = useRef(null);
    const navigate = useNavigate();
    const { courseId, moduleId } = useParams();
    const openModal = () => {
        onOpenTask(taskInfo);
        dialogRef.current.showModal();
    };

    const closeModal = () => {
        dialogRef.current.close();
    };

    function navigateToCourse(task_id) {
        navigate(`/course/${courseId}/modules/${moduleId}/task/${task_id}`);
    }

    return (
        <div>
            <button className="btn btn-neutral mt-5" onClick={openModal}>
                {taskInfo.task_state === 'todo' ? "Начать задание" : "Открыть задание"}
            </button>
            <dialog ref={dialogRef} className="modal" id={`modalTask-${taskInfo.task_id}`}>
                <div className="modal-box">
                    <h3 className="font-bold text-lg">{taskInfo.title}</h3>
                    <p className="py-4">{taskInfo.description}</p>
                    <div className="text-primary hover:cursor-pointer" onClick={() => navigateToCourse(taskInfo.task_id)}>
                        Инструкция к заданию
                    </div>
                    <button className="btn btn-primary mt-5 text-white" onClick={() => onSubmitForReview(taskInfo)}>
                        Отправить на проверку
                    </button>
                </div>
                <form method="dialog" className="modal-backdrop" onClick={closeModal}>
                    <button>close</button>
                </form>
            </dialog>
        </div>
    );
};

export default ModuleTaskModal;
