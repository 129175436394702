import {makeAutoObservable} from "mobx";

export default class CourseStore {
    constructor() {
        this._courses = []
        makeAutoObservable(this)
    }

    setCourse(courses) {
        this._courses = courses
    }

    get currentCourse() {
        return this._courses
    }
}