import {$authHost, $host} from "./index";
import { jwtDecode } from "jwt-decode"

export const createCourse = async (course) => {
    const {data} = await $authHost.post('api/course', course)
    return data
}

export const createModule = async (module) => {
    const {data} = await $authHost.post('api/course/createModule', module)
    return data
}

export const createTask = async (task) => {
    const {data} = await  $authHost.post('api/task/', task)
    return data
}

export const getCurrentCourses = async () => {
    try {
        const { data } = await $authHost.get('api/course/currentCourses');
        return data;
    } catch (error) {
        console.error('Error fetching current courses:', error);
        throw error;
    }
}

export const getModulesByCourseId = async (courseId) => {
    try {
        const { data } = await $authHost.get(`api/course/${courseId}`);
        return data;
    } catch (error) {
        console.error(`Error fetching modules for courseId ${courseId}:`, error);
        throw error;
    }
};

export const getTasksByModuleId = async (courseId, moduleId) => {
    try {
        const { data } = await $authHost.get(`api/task?courseId=${courseId}&courseModuleId=${moduleId}`);
        return data;
    } catch (error) {
        console.error(`Error fetching tasks:`, error);
        throw error;
    }
};


export const updateTasksOnServer = async (updatedTask, userId, courseId, moduleId) => {
    try {
        const response = await $authHost.post(`api/task/update`, { tasks: [updatedTask], userId, courseId, moduleId });
        console.log(response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getPassedCourses = async () => {
    try {
        const { data } = await $authHost.get('api/course/passedCourses');
        return data;
    } catch (error) {
        console.error('Error fetching passed courses:', error);
        throw error;
    }
}

export const addUserPassedCourses = async (userId, courseId) => {
    try {
        const {data} = await  $authHost.post('api/course/addPassedCourse', {userId, courseId})
        return data
    } catch (error) {
        console.error('Error add passed course to user:', error);
        throw error;
    }
}

export const createNewAchievement = async (achievement) => {
    try {
        const {data} = await $authHost.post('api/course/addAchievement', achievement)
        return data
    } catch (error) {
        console.error('Error on create new achievement:', error);
        throw error;
    }
}

export const addAchievementToUser = async (userId, achievementId) => {
    try {
        const {data} = await  $authHost.post('api/course/addAchievementToUser', {userId, achievementId})
        return data
    } catch (error) {
        console.error('Error on add achievement to user:', error);
        throw error;
    }
}

export const getAchievementUser = async () => {
    try {
        const { data } = await $authHost.get('api/course/achievement');
        return data;
    } catch (error) {
        console.error('Error fetching user achievements:', error);
        throw error;
    }
}

export const removeAchievementToUser = async (userID, achievementId)  => {
    try {
        const { data } = await $authHost.delete(`api/course/removeAchievementToUser?userId=${userID}&achievementId=${achievementId}`);
        return data;
    } catch (error) {
        console.error('Error delete user achievements:', error);
        throw error;
    }
}

export const addTaskInfo = async (taskInfo) => {
    try {
        const { data } = await $authHost.post('api/task/addInfo', taskInfo);
        return data;
    } catch (error) {
        console.error('Error add task info:', error);
        throw error;
    }
}

export const updateTaskInfo = async (taskInfo) => {
    try {
        const { data } = await $authHost.put('api/task/updateInfo', taskInfo);
        return data;
    } catch (error) {
        console.error('Error updating task info:', error);
        throw error;
    }
};

export const fetchTaskInfo = async (moduleId, taskId) => {
    try {
        const token = localStorage.getItem('token');
        const { data } = await $host.get(`api/task/module/${moduleId}/task/${taskId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return {
            content: data.content
        };
    } catch (error) {
        console.error('Error fetching task info:', error);
        throw error;
    }
};




export const editGroupLessonsCount = async (groupId, lessonsCount) => {
    try {
        const {data} = await  $authHost.post('api/course/editGroupLessonsCount', {groupId, lessonsCount})
        return data
    } catch (error) {
        console.error('Ошибка при изменении количества проведённных уроков')
        throw error;
    }
}

export const editGroupTeacher = async (groupId, teacherId) => {
    try {
        const {data} = await  $authHost.post('api/course/editGroupTeacher', {groupId, teacherId})
        return data
    } catch (error) {
        console.error('Ошибка при изменении учителя для группы')
        throw error;
    }
}