import React, {useContext, useEffect, useState} from 'react';
import NavBar from "../components/NavBar";
import RootLayout from "../components/RootLayout";
import Methodist from "../components/admin/Methodist";
import Teacher from "../components/admin/Teacher";
import {Context} from "../index";
import BlockUser from "../components/BlockUser";
import Administrator from "../components/admin/Administrator";
import {ReactNotifications} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'
import {getUser} from "../http/userAPI";
import ChangeUserRole from "../components/admin/administrator/ChangeUserRole";
import DeleteUser from "../components/admin/administrator/DeleteUser";

const Admin = () => {
    const {user} = useContext(Context);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        getUser().then(data => {
            user.setUser(data);
            setIsAdmin(data.role === 'ADMIN'); // Устанавливаем состояние isAdmin в зависимости от роли пользователя
        });
    }, [user.setUser]);

    return (
        <RootLayout>
            <ReactNotifications />
            <NavBar />

            {isAdmin  ?
            <div className="mx-2.5 mt-5 w-fit overflow-scroll h-[87vh]">
                <div className="collapse collapse-arrow border rounded-3xl lg:p-5">
                    <input type="radio" name="my-accordion-2" />
                    <div className="collapse-title text-xl font-medium">
                        Открыть список групп
                    </div>
                    <div className="collapse-content">
                        <Teacher />
                    </div>
                </div>
                <div className="collapse collapse-arrow border rounded-3xl md:p-5 mt-5">
                    <input type="radio" name="my-accordion-2" />
                    <div className="collapse-title text-xl font-medium">
                        Добавить новый контент
                    </div>
                    <div className="collapse-content">
                        <Methodist />
                    </div>
                </div>
                <div className="collapse collapse-arrow border rounded-3xl md:p-5 mt-5">
                    <input type="radio" name="my-accordion-2" />
                    <div className="collapse-title text-xl font-medium">
                        Редактирование пользователей
                    </div>
                    <div className="collapse-content">
                        <Administrator />
                    </div>
                </div>

                <div className="text-2xl border-b pb-2.5 text-slate-400 mt-5">Редактирование пользователей</div>
                <div className="md:mt-5 md:mx-0 md:h-fit overflow-scroll md:grid md:grid-cols-2 md:gap-5">
                    <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                        <div className="font-semibold text-3xl text-accent">Изменить роль пользователя</div>
                        <ChangeUserRole />
                    </div>
                    <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                        <div className="font-semibold text-3xl text-accent">Удаление пользователя</div>
                        <DeleteUser/>
                    </div>

                </div>
            </div>
            :
            <BlockUser/>
            }



        </RootLayout>

    );
};

export default Admin;