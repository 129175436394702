import React, {useState} from 'react';
import {createNewAchievement} from "../../../http/courseAPI";
import {Store} from "react-notifications-component";

const AddAchievement = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [background, setBackground] = useState(null)
    const [logo, setLogo] = useState(null)

    const selectBackgroundFile = e => {
        setBackground(e.target.files[0])
    }

    const selectLogoFile = e => {
        setLogo(e.target.files[0])
    }

    const closeModal = () => {
        document.getElementById('modal_add_achievement').close();
    }

    const createAchievement = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('background', background);
        formData.append('logo', logo);

        createNewAchievement(formData).then(data => {
            closeModal();
            Store.addNotification({
                title: "Успех!",
                message: "Достижение успешно добавлено",
                type: "success",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }).catch(error => {
            closeModal();
            Store.addNotification({
                title: "Ошибка!",
                message: "Произошла ошибка при добавлении достижения",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        });
    }

    return (
        <div>
            <div>
                <button
                    className="btn btn-outline w-fit border-white text-white hover:bg-white hover:text-inherit hover:border-white"
                    onClick={()=>document.getElementById('modal_add_achievement').showModal()}
                >Добавить</button>
                <dialog id="modal_add_achievement" className="modal">
                    <div className="modal-box">
                        <div className="flex items-center justify-between mb-5">
                            <h3 className="font-bold text-2xl">Добавление нового достижения</h3>
                            <kbd className="kbd">ESC</kbd>
                        </div>
                        <hr/>
                        <form className="flex flex-col mt-5" onSubmit={createAchievement}>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Введите название достижения</span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Название достижения..."
                                    className="input input-bordered w-full"
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                />
                            </label>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Введите описание достижения</span>
                                </div>
                                <textarea
                                    className="textarea textarea-bordered h-24"
                                    placeholder="Описание достижения..."
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                ></textarea>
                            </label>

                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Загрузите баннер достижения</span>
                                </div>
                                <input
                                    type="file"
                                    className="file-input w-full"
                                    onChange={selectBackgroundFile}
                                />
                            </label>

                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Загрузите логотип достижения</span>
                                </div>
                                <input
                                    type="file"
                                    className="file-input w-full"
                                    onChange={selectLogoFile}
                                />
                            </label>

                            <button className="btn btn-primary mt-5" type='submit'>Добавить достижение</button>
                        </form>

                    </div>
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                </dialog>
            </div>
        </div>
    );
};

export default AddAchievement;