import React, {useContext, useEffect, useState} from 'react';
import {addUserToCourse, deleteUser} from "../../../http/userAPI";
import {Store} from "react-notifications-component";
import {Context} from "../../../index";
import {getAllCourses, getAllStudents} from "../../../http/createAPI";

const AddStudent = () => {
    const [students, setStudents] = useState([]);
    const [courses, setCourses] = useState([]);

    const [selectedStudentId, setSelectedStudentId] = useState('');
    const [selectedCourseId, setSelectedCourseId] = useState('');

    const {user} = useContext(Context)

    useEffect(() => {
        const fetchData = async () => {
            const studentsData = await getAllStudents();
            setStudents(studentsData);
            const coursesData = await getAllCourses();
            setCourses(coursesData);
        };
        fetchData();
    }, []);

    const handleAddStudent = async (e) => {
        e.preventDefault()
        try {
            if (user.user.role === 'ADMIN' || user.user.role === "ADMINISTRATOR") {
                await addUserToCourse(selectedStudentId, selectedCourseId);
                Store.addNotification({
                    title: "Получилось!",
                    message: "Пользователь успешно добавлен на курс",
                    type: "success",
                    insert: "top",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                document.getElementById('modal_add_student').close();
            } else {
                alert('Увы, но вы не администратор. Если это ошибка, свяжитесь с разработчиками')
            }

        } catch (error) {
            Store.addNotification({
                title: "Упс, что-то пошло не так!",
                message: "Пользователя не получилось добавить на курс",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            document.getElementById('modal_add_student').close();
        }
    }

    return (
        <div>
            <button
                className="btn btn-outline w-fit text-accent"
                onClick={()=>document.getElementById('modal_add_student').showModal()}
            >Добавить</button>
            <dialog id="modal_add_student" className="modal">
                <div className="modal-box">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="font-bold text-2xl text-accent">Добавление нового ученика</h3>
                        <kbd className="kbd">ESC</kbd>
                    </div>
                    <hr/>
                    <form className="flex flex-col mt-5" onSubmit={handleAddStudent}>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Выберите ученика</span>
                            </div>
                            <select
                                className="select select-bordered w-full"
                                value={selectedStudentId}
                                onChange={e => setSelectedStudentId(e.target.value)}
                            >
                                <option disabled value="">Выберите ученика</option>
                                {students.map(student => (
                                    <option key={student.id} value={student.id}>{student.name}</option>
                                ))}
                            </select>
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Выберите курс</span>
                            </div>
                            <select
                                className="select select-bordered w-full"
                                value={selectedCourseId}
                                onChange={e => setSelectedCourseId(e.target.value)}
                            >
                                <option disabled value="">Выберите курс</option>
                                {courses.map(course => (
                                    <option key={course.id} value={course.id}>{course.title}</option>
                                ))}
                            </select>
                        </label>
                        <div role="alert" className="alert alert-warning mt-5">
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                            <span>Убедитесь, что ученик уже записан в группу для этого курса</span>
                        </div>
                        <button className="btn btn-primary mt-5" type="submit">Добавить ученика на курс</button>
                    </form>

                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    );
};

export default AddStudent;