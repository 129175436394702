export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const HOME_ROUTE = '/'
export const TASK_ROUTE = '/course/:courseId/modules/:moduleId/task'
export const COURSE_ROUTE = '/course'
export const MODULE_ROUTE = '/course/:courseId/modules'
export const ADMINISTRATOR_ROUTE = '/administrator'
export const  METHODIST_ROUT = '/methodist'
export const TEACHER_ROUT = '/teacher'
export const ADD_TASK_INFO = '/instruction'