import {$authHost, $host} from "./index";
import { jwtDecode } from "jwt-decode"

export const registration = async (login, name, password) => {
    const {data} = await $host.post('api/user/registration', {login, name, password, role:'USER'})
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const login = async (login, password) => {
    const {data} = await $host.post('api/user/login', {login, password})
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth')
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const getUser = async () => {
    const {data} = await $authHost.get('api/user/getUser')
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const changeAvatar = async (id, avatar) => {
    try {
        const formData = new FormData()
        formData.append('id', id)
        formData.append('avatar', avatar)
        await $authHost.post('api/user/changeAvatar', formData);
    } catch (error) {
        console.log('Произошла ошибка при смене автара:');
        console.log(error);
        throw error;
    }

}

export const changeInfo = async (id, info) => {
    await $authHost.put('api/user/changeInfo', { id, info });
}

export const changeRole = async (currentUserRole, id, role) => {
    try {
        const response = await $authHost.put('api/user/changeRole', {currentUserRole, id, role });
        return response.data; // Возвращаем данные, если запрос выполнен успешно
    } catch (error) {
        console.log('Произошла ошибка при изменении роли пользователя:');
        console.log(error);
        throw error; // Пробрасываем ошибку для обработки в вызывающем коде
    }
}

export const deleteUser = async (id) => {
    try {
        const response = await $authHost.delete('api/user/deleteUser', { data: { id } })
        return response.data
    } catch (error) {
        console.log('Произошла ошибка при изменении роли пользователя:');
        console.log(error);
        throw error;
    }
}

export const addUserToCourse = async (userID, courseID) => {
    try {
        const response = await $authHost.post('api/user/addUserToCourse', { userID, courseID });
        return response.data;
    } catch (error) {
        console.log('Произошла ошибка при добавлении пользователя на курс:');
        console.log(error);
        throw error;
    }
};

export const removeUserFromCourse = async (userID, courseID) => {
    try {
        const response = await $authHost.delete('api/user/removeUserFromCourse', { data: { userID, courseID } });
        return response.data;
    } catch (error) {
        console.log('Произошла ошибка при удалении пользователя из курса:');
        console.log(error);
        throw error;
    }
};

export const createGroup = async (groupName, teacherId, courseId) => {
    try {
        const response = await $authHost.post('api/user/addGroup', {groupName, teacherId, courseId})
        return response.data;
    } catch (error) {
        console.log('Произошла ошибка при добавлении курса (Отправка запроса):');
        console.log(error);
        throw error;
    }
}

export const addUserToGroup = async (userID, groupID) => {
    try {
        const response = await $authHost.post('api/user/addUserToGroup', { userID, groupID });
        return response.data;
    } catch (error) {
        console.log('Произошла ошибка при добавлении пользователя в группу:');
        console.log(error);
        throw error;
    }
}

export const getModuleProgress = async (userId, moduleIds) => {
    try {
        const params = new URLSearchParams({ userId, moduleIds: moduleIds.join(',') });
        const { data } = await $authHost.get(`api/user/getModuleProgress?${params}`);
        return data;
    } catch (error) {
        console.error('Ошибка при получении прогресса модулей:', error);
        throw error;
    }
};

export const removeUserFromGroup = async (userID, groupID) => {
    try {
        const response = await $authHost.delete('api/user/removeUserFromGroup', { data: { userID, groupID } });
        return response.data;
    } catch (error) {
        console.log('Произошла ошибка при удалении пользователя из группы:');
        console.log(error);
        throw error;
    }
};