import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../index";
import {getUser} from "../http/userAPI";
import RootLayout from "../components/RootLayout";
import NavBar from "../components/NavBar";
import BlockUser from "../components/BlockUser";
import Administrator from "../components/admin/Administrator";
import {ReactNotifications} from "react-notifications-component";

const AdministratorPage = () => {
    const {user} = useContext(Context)
    const [isAdministrator, setIsAdministrator] = useState(false);

    useEffect(() => {
        getUser().then(data => {
            user.setUser(data);
            setIsAdministrator(data.role === 'ADMINISTRATOR' || data.role === "ADMIN");
        });
    }, [user.setUser]);


    return (
        <RootLayout>
            <ReactNotifications />
            <NavBar />
            <div className="mt-5 mx-5 h-[89vh] md:h-[87vh] overflow-scroll lg:mx-0">
                { isAdministrator  ? <Administrator /> : <BlockUser/> }
            </div>
        </RootLayout>
    );
};

export default AdministratorPage;