import React, {useEffect, useRef, useState} from 'react';
import {createModule} from "../../../http/courseAPI";
import {Store} from "react-notifications-component";
import {getAllCourses} from "../../../http/createAPI";

const AddModule = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [courseId, setCourseId] = useState(0)
    const [courses, setCourses] = useState([]);
    const [dropdownTitle, setDropdownTitle] = useState('Выберите курс');
    const dropdownRef = useRef(null);

    useEffect(() => {
        getAllCourses().then(data => {
            setCourses(data);
        }).catch(error => {
            console.error("Ошибка при получении курсов:", error);
        });
    }, []);

    const selectCourse = (course) => {
        setCourseId(course.id);
        setDropdownTitle(`${course.id} - ${course.title}`);
        if (dropdownRef.current) {
            dropdownRef.current.classList.remove('open');
        }
    };

    const toggleDropdown = () => {
        if (dropdownRef.current) {
            dropdownRef.current.classList.toggle('open');
        }
    };

    const closeModal = () => {
        document.getElementById('modal_add_module').close();
    }

    const addModule = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('courseId', courseId);

        createModule(formData).then(data => {
            closeModal();
            Store.addNotification({
                title: "Готово!",
                message: "Модуль успешно добавлен",
                type: "success",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }).catch(error => {
            closeModal();
            Store.addNotification({
                title: "Ошибка!",
                message: "Произошла ошибка при добавлении модуля",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        });
    }

    return (
        <div>
            <div>
                <button
                    className="btn btn-outline w-fit border-white text-white hover:bg-white hover:text-inherit hover:border-white"
                    onClick={()=>document.getElementById('modal_add_module').showModal()}
                >Добавить</button>
                <dialog id="modal_add_module" className="modal">
                    <div className="modal-box">
                        <div className="flex items-center justify-between mb-5">
                            <h3 className="font-bold text-2xl">Добавление нового модуля</h3>
                            <kbd className="kbd">ESC</kbd>
                        </div>
                        <hr/>
                        <form className="flex flex-col mt-5" onSubmit={addModule}>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Введите название модуля</span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Название модуля..."
                                    className="input input-bordered w-full"
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                />
                            </label>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Введите описание модуля</span>
                                </div>
                                <textarea
                                    className="textarea textarea-bordered h-24"
                                    placeholder="Описание модуля..."
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                ></textarea>
                            </label>
                            <div className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-lg">Выберите курс для модуля</span>
                                </div>
                                <div className="dropdown" ref={dropdownRef}>
                                    <div tabIndex={0} role="button" className="btn m-1" onClick={toggleDropdown}>{dropdownTitle}</div>
                                    <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full">
                                        {courses.map(course => (
                                            <li key={course.id} onClick={() => selectCourse(course)}>
                                                <a>{course.id} - {course.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <button className="btn btn-primary mt-5" type='submit'>Добавить модуль</button>
                        </form>

                    </div>
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                </dialog>
            </div>
        </div>
    );
};

export default AddModule;