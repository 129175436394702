import React, {useContext, useEffect, useState} from 'react';
import { Context } from "../index";
import ModuleTaskModal from "./ModuleTaskModal";
import {getTasksByModuleId, updateTasksOnServer} from "../http/courseAPI";
import { observer } from "mobx-react-lite";
import AccessDeniedAlert from "./AccessDeniedAlert";

const ModuleTask = ( { courseId, moduleId } ) => {
    const { user } = useContext(Context)
    const { tasks } = useContext(Context);
    const [accessDenied, setAccessDenied] = useState(false);

    const handleOpenTask = async (task) => {
        if (task.task_state !== 'doing') {
            try {
                const updatedTask = { ...task, task_state: 'doing' };
                tasks.setTasks(tasks.currentTask.map((t) => (t.task_id === updatedTask.task_id ? updatedTask : t)));
                await updateTasksOnServer(updatedTask, user.user.id, courseId, moduleId);
            } catch (error) {
                console.error('Error updating task:', error);
            }
        }
    };

    const handleSubmitForReview = async (task) => {
        if (task.task_state !== 'done') {
            try {
                const updatedTask = { ...task, task_state: 'done' };
                tasks.setTasks(tasks.currentTask.map((t) => (t.task_id === updatedTask.task_id ? updatedTask : t)));
                await updateTasksOnServer(updatedTask, user.user.id, courseId, moduleId);
            } catch (error) {
                console.error('Error updating task:', error);
            }
        }
    };

    const loadTasks = async () => {
        if (moduleId) {
            try {
                const tasksData = await getTasksByModuleId(courseId, moduleId);
                tasks.setTasks(tasksData);
                setAccessDenied(false); // Сброс состояния ошибки при успешной загрузке
            } catch (error) {
                console.error('Error fetching tasks:', error);
                if (error.response && error.response.status === 403) {
                    setAccessDenied(true); // Устанавливаем флаг ошибки доступа
                }
            }
        }
    };

    useEffect(() => {
        tasks.restoreSelectedModuleIdFromLocalStorage();
        loadTasks();
    }, [tasks, moduleId]);

    if (accessDenied) {
        return <AccessDeniedAlert />;
    }

    return (
        <div
            className="border border-primary rounded-3xl h-[82.5vh] lg:h-[87vh] m-5 p-5 pb-0 lg:mx-0 overflow-scroll lg:flex lg:grid-cols-3 lg:gap-5"
        >
            {['todo', 'doing', 'done'].map((status, index) => (
                <div key={index} className="lg:border-r last:lg:border-r-0 lg:pr-5 overflow-scroll w-full">
                    <div className="text-2xl font-semibold text-center border-b border-primary pb-5">
                        {status === 'todo' && 'Задачи'}
                        {status === 'doing' && 'Делаю'}
                        {status === 'done' && 'На проверке'}
                    </div>
                    {tasks.currentTask
                        .filter((item) => item.task_state === status)
                        .sort((a, b) => a.task_id - b.task_id)
                        .map((item, index) => (
                            <div
                                key={item.id}
                                className="item border border-primary shadow-lg rounded-3xl p-5 my-5"
                            >
                                <div className="item-title font-semibold text-lg mb-2.5">
                                    {item.task_id}. {item.title}
                                </div>
                                <div className="item-descr ">{item.description}</div>
                                <ModuleTaskModal
                                    taskInfo={item}
                                    onOpenTask={handleOpenTask}
                                    onSubmitForReview={handleSubmitForReview}
                                />
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
};

export default observer(ModuleTask);