import React, {useContext, useEffect, useState} from 'react';
import Teacher from "../components/admin/Teacher";
import NavBar from "../components/NavBar";
import RootLayout from "../components/RootLayout";
import {Context} from "../index";
import {getUser} from "../http/userAPI";
import BlockUser from "../components/BlockUser";
import {ReactNotifications} from "react-notifications-component";

const TeacherPage = () => {
    const {user} = useContext(Context)
    const [isTeacher, setIsTeacher] = useState(false);

    useEffect(() => {
        getUser().then(data => {
            user.setUser(data);
            setIsTeacher(data.role === 'TEACHER' || data.role === "ADMIN");
        });
    }, [user.setUser]);
    return (
        <RootLayout>
            <ReactNotifications />
            <NavBar />
            <div className="mt-5 mx-5 h-[86vh] lg:h-[89vh] overflow-scroll lg:mx-0">
                { isTeacher  ? <Teacher /> : <BlockUser/> }
            </div>
        </RootLayout>
    );
};

export default TeacherPage;