import {makeAutoObservable} from "mobx";

export default class ModuleStore {
    constructor() {
        this._modules = []
        makeAutoObservable(this)
    }

    setModules(modules) {
        this._modules = modules
    }

    get currentModule() {
        return this._modules
    }
}