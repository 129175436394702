import {$authHost} from "./index";

export const getAllCourses = async () => {
    const {data} = await $authHost.get('api/create/getCourses')
    return data
}

export const getAllModules = async () => {
    const {data} = await $authHost.get('api/create/getModules')
    return data
}


export const getAllStudents = async () => {
    const {data} = await $authHost.get('api/create/getStudents')
    return data
}

export const getAllAchievements = async () => {
    const {data} = await $authHost.get('api/create/getAchievements')
    return data
}

export const getAllTeachers = async () => {
    const {data} = await $authHost.get('api/create/getTeachers')
    return data
}

export const getAllGroups = async () => {
    const {data} = await $authHost.get('api/create/getGroups')
    return data
}
