import React, {useEffect, useRef, useState} from 'react';
import { ReactNotifications, Store } from "react-notifications-component";
import NavBar from "../components/NavBar";
import RootLayout from "../components/RootLayout";
import {addTaskInfo, updateTaskInfo} from "../http/courseAPI";
import {getAllModules} from "../http/createAPI";

const AddTaskInfo = () => {
    const [taskId, setTaskId] = useState(0)
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState(null);
    const [file, setFile] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        getAllModules().then(data => {
            setModules(data);
        }).catch(error => {
            console.error("Ошибка при получении модулей:", error);
        });
    }, []);


    const selectModule = (module) => {
        setSelectedModule(module);
        if (dropdownRef.current) {
            dropdownRef.current.classList.remove('open');
        }
    };

    const toggleDropdown = () => {
        if (dropdownRef.current) {
            dropdownRef.current.classList.toggle('open');
        }
    };

    const notify = (title, message, type) => {
        Store.addNotification({
            title,
            message,
            type,
            insert: "top",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    };

    const addNewTaskInfo = (e) => {
        e.preventDefault();

        if (!file) {
            console.error('No file selected');
            return;
        }

        const formData = new FormData();
        formData.append('courseModuleId', selectedModule.id);
        formData.append('courseTaskId', taskId);
        formData.append('content', file);

        addTaskInfo(formData).then(data => {
            notify("Готово", "Инструкция успешно добавлена!", "success");
        }).catch(error => {
            notify("Ошибка", "Произошла ошибка при добавлении инструкции", "danger");
        });
    };

    const updateExistingTaskInfo = (e) => {
        e.preventDefault();

        if (!file) {
            console.error('No file selected');
            return;
        }

        const formData = new FormData();
        formData.append('courseModuleId', selectedModule.id);
        formData.append('courseTaskId', taskId);
        formData.append('content', file);

        updateTaskInfo(formData).then(data => {
            notify("Готово", "Инструкция успешно обновлена!", "success");
        }).catch(error => {
            notify("Ошибка", "Произошла ошибка при обновлении инструкции", "danger");
        });
    };

    return (
        <RootLayout>
            <ReactNotifications />
            <NavBar />
            <div className="mt-5 h-[88vh] mx-5 overflow-scroll md:mx-0 md:mr-5 lg-mx-0">
                <div className="flex items-center justify-between mb-5">
                    <h3 className="font-bold text-2xl">Добавление инструкции для задания</h3>
                </div>
                <form className="flex flex-col my-5">
                    <label className="form-control w-full mb-5">
                        <div className="label">
                            <span className="label-text text-lg">Введите ID задания</span>
                        </div>
                        <input
                            type="text"
                            placeholder="ID задания..."
                            className="input input-bordered w-full"
                            value={taskId}
                            onChange={e => {setTaskId(e.target.value)}}
                        />
                    </label>

                    <label className="form-control w-full mb-5">
                        <div className="label">
                            <span className="label-text text-lg">Введите ссылку на загрузку инструкции</span>
                        </div>
                        <input
                            type="text"
                            placeholder="Ссылка"
                            className="input input-bordered w-full"
                            value={file}
                            onChange={e => {
                                setFile(e.target.value)
                            }}
                        />
                    </label>

                    <div className="form-control w-full mb-5">
                        <div className="label">
                            <span className="label-text text-lg">Выберите модуль</span>
                        </div>
                        <div className="dropdown" ref={dropdownRef}>
                            <div tabIndex={0} role="button" className="btn m-1" onClick={toggleDropdown}>
                                {selectedModule ? selectedModule.title : "Выберите модуль"}
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full">
                                {modules.map(module => (
                                    <li key={module.id} onClick={() => selectModule(module)}>
                                        <a>{module.title}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="mt-5 flex gap-5 justify-center">
                        <button className="btn btn-neutral" onClick={updateExistingTaskInfo}>Обновить инструкцию для задания</button>
                        <button className="btn btn-primary" onClick={addNewTaskInfo}>Добавить инструкцию для задания</button>
                    </div>

                </form>
            </div>
        </RootLayout>
    );
};

export default AddTaskInfo;