import Admin from "./pages/Admin";
import {
    ADD_TASK_INFO,
    ADMIN_ROUTE, ADMINISTRATOR_ROUTE,
    COURSE_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE, METHODIST_ROUT,
    MODULE_ROUTE,
    REGISTRATION_ROUTE,
    TASK_ROUTE, TEACHER_ROUT
} from "./utils/consts";
import Task from "./pages/Task";
import Home from "./pages/Home";
import CoursePage from "./pages/CoursePage";
import Auth from "./pages/Auth";
import Education from "./pages/Education";
import ModulePage from "./pages/modulePage";
import MethodistPage from "./pages/MethodistPage";
import TeacherPage from "./pages/TeacherPage";
import AddTaskInfo from "./pages/AddTaskInfo";
import AdministratorPage from "./pages/AdministratorPage";

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
    {
        path: `${TASK_ROUTE}/:taskId`,
        Component: Task
    },
    {
        path: `${MODULE_ROUTE}/:moduleId`,
        Component: ModulePage
    },
    {
        path: HOME_ROUTE,
        Component: Home
    },
    {
        path: COURSE_ROUTE,
        Component: Education
    },
    {
        path: `${COURSE_ROUTE}/:courseId`,
        Component: CoursePage
    },
    {
        path: ADMINISTRATOR_ROUTE,
        Component: AdministratorPage
    },
    {
        path: METHODIST_ROUT,
        Component: MethodistPage
    },
    {
        path: TEACHER_ROUT,
        Component: TeacherPage
    },
    {
        path: ADD_TASK_INFO,
        Component: AddTaskInfo
    }
]

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Auth
    },
]