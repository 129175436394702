import React, { useEffect, useState } from 'react';
import {approveModule, getStudentModuleStatus, incrementLesson} from "../../../http/teacherAPI";
import { getModulesByCourseId } from "../../../http/courseAPI";
import {Store} from "react-notifications-component";

const GroupModal = ({ students, groupName, courseId, groupId, updateLessonsCount }) => {
    const [modules, setModules] = useState([]);
    const [studentModules, setStudentModules] = useState({});

    useEffect(() => {
        getModulesByCourseId(courseId).then(data => {
            setModules(data);
        }).catch(error => {
            // Обработать ошибки
        });

        getStudentModuleStatus(groupId).then(data => {
            const studentModulesData = data.reduce((acc, studentData) => {
                acc[studentData.studentId] = studentData.modules.reduce((modAcc, mod) => {
                    modAcc[mod.moduleId] = mod.isCompleted;
                    return modAcc;
                }, {});
                return acc;
            }, {});
            setStudentModules(studentModulesData);
        }).catch(error => {
            // Обработать ошибки
        });
    }, [courseId, groupId]);

    const handleCheckboxChange = (moduleId, userId, isChecked) => {
        setStudentModules(prevState => ({
            ...prevState,
            [userId]: {
                ...prevState[userId],
                [moduleId]: isChecked
            }
        }));
    };

    const closeModal = () => {
        document.getElementById(`group-${groupId}`).close();
    };

    const handleConfirmClick = async (e) => {
        try {
            e.preventDefault()
            for (const [userId, modules] of Object.entries(studentModules)) {
                for (const [moduleId, isCompleted] of Object.entries(modules)) {
                    await approveModule(moduleId, userId, isCompleted, courseId);
                }
            }
            closeModal();
            Store.addNotification({
                title: "Получилось!",
                message: "Всем ученикам подтвердили прохождения модуля!",
                type: "success",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        } catch (error) {
            closeModal();
            Store.addNotification({
                title: "Упс, что-то пошло не так!",
                message: "Ученикам не удалось подтвердить прохождения модуля...",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }
    };

    const handleConductLesson = () => {
        incrementLesson(groupId).then(data => {
            updateLessonsCount(groupId, data.lessonsCount);
            closeModal();
            Store.addNotification({
                title: "Получилось!",
                message: "Урок проведён, выдыхаем!",
                type: "success",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }).catch(error => {
            console.error(error);
            Store.addNotification({
                title: "Упс, что-то пошло не так!",
                message: "Не удалось засчитать проведение урока...",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        });
    };

    return (
        <div>
            <button className="btn btn-outline ml-5" onClick={() => document.getElementById(`group-${groupId}`).showModal()}>Войти</button>
            <dialog id={`group-${groupId}`} className="modal">
            <div className="modal-box max-w-screen-2xl">
                    <h3 className="font-bold text-lg mb-5">{groupName}</h3>
                    <div className="overflow-x-auto">
                        <form onSubmit={handleConfirmClick}>
                            <table className="table table-xs lg:table-md table-pin-rows table-pin-cols">
                                <thead>
                                <tr>
                                    <th></th>
                                    <td>Имя</td>
                                    {modules.map((module, index) => (
                                        <td key={module.id} className="text-center">Модуль {index + 1}</td>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {students.map((student, index) => (
                                    <tr key={student.id || index}>
                                        <th>{index + 1}</th>
                                        <td>
                                            <div className="flex items-center gap-3">
                                                <div className="avatar">
                                                    <div className="mask mask-circle w-12 h-12">
                                                        {student.avatar ? (
                                                            <img src={process.env.REACT_APP_API_URL + 'profiles/' + student.avatar} alt="Avatar" />
                                                        ) : (
                                                            <div className="bg-neutral text-neutral-content rounded-3xl h-full flex items-center justify-center">
                                                                <span className="text-3xl">{student.name[0]}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="font-bold">{student.name}</div>
                                                </div>
                                            </div>
                                        </td>
                                        {modules.map(module => (
                                            <td key={module.id} className="text-center">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox"
                                                    checked={studentModules[student.id]?.[module.id] || false}
                                                    onChange={(e) => handleCheckboxChange(module.id, student.id, e.target.checked)}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            <button className="btn btn-primary mt-5 text-white" type='submit'>Подтвердить</button>
                            <div className="btn btn-outline ml-5" onClick={handleConductLesson}>Провести урок</div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    );
};

export default GroupModal;
