import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../../index";
import {addUserToGroup} from "../../../http/userAPI";
import {Store} from "react-notifications-component";
import {getAllGroups, getAllStudents} from "../../../http/createAPI";

const AddUserToGroup = () => {
    const [userID, setUserID] = useState('');
    const [groupID, setGroupID] = useState('');
    const [students, setStudents] = useState([]);
    const [groups, setGroups] = useState([]);

    const { user } = useContext(Context);

    useEffect(() => {
        getAllStudents().then(data => setStudents(data)); // Загрузка данных об учениках
        getAllGroups().then(data => setGroups(data));   // Загрузка данных о группах
    }, []);

    const handleAddStudentToGroup = async (e) => {
        e.preventDefault()
        try {
            if (user.user.role === 'ADMIN' || user.user.role === "ADMINISTRATOR") {
                await addUserToGroup(userID, groupID);
                Store.addNotification({
                    title: "Получилось!",
                    message: "Пользователь успешно добавлен в группу",
                    type: "success",
                    insert: "top",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                document.getElementById('modal_add_student_group').close();
            } else {
                alert('Увы, но вы не администратор. Если это ошибка, свяжитесь с разработчиками')
            }

        } catch (error) {
            Store.addNotification({
                title: "Упс, что-то пошло не так!",
                message: "Пользователя не получилось добавить в группу",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            document.getElementById('modal_add_student_group').close();
        }
    }

    return (
        <div>
            <button
                className="btn btn-outline w-fit text-accent"
                onClick={()=>document.getElementById('modal_add_student_group').showModal()}
            >Добавить</button>
            <dialog id="modal_add_student_group" className="modal">
                <div className="modal-box">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="font-bold text-2xl text-accent">Добавление ученика в группу</h3>
                        <kbd className="kbd">ESC</kbd>
                    </div>
                    <hr/>
                    <form className="flex flex-col mt-5" onSubmit={handleAddStudentToGroup}>
                        <label className="form-control w-full">
                            <span className="label-text text-lg">Выберите ученика</span>
                            <select
                                className="select select-bordered w-full"
                                value={userID}
                                onChange={e => setUserID(e.target.value)}
                            >
                                <option disabled value="">Выберите ученика</option>
                                {students.map(student => (
                                    <option key={student.id} value={student.id}>{student.name}</option>
                                ))}
                            </select>
                        </label>
                        <label className="form-control w-full mt-2.5">
                            <span className="label-text text-lg">Выберите группу</span>
                            <select
                                className="select select-bordered w-full"
                                value={groupID}
                                onChange={e => setGroupID(e.target.value)}
                            >
                                <option disabled value="">Выберите группу</option>
                                {groups.map(group => (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))}
                            </select>
                        </label>

                        <button className="btn btn-primary mt-5" type="submit">Добавить ученика в группу</button>
                    </form>

                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    );
};
export default AddUserToGroup;