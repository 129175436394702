import SideBar from "./SideBar";
import React from "react";

function RootLayout({children}) {
    return (
        <div className="flex md:gap-5 relative">
            <SideBar />
            <main className="max-w-5xl flex-1 mx-auto">{children}</main>
        </div>
    )
}

export default RootLayout;