import React, { useContext, useEffect } from 'react';
import GroupModal from "./teacherModals/GroupModal";
import { Context } from "../../index";
import { getLessonsCount, getTeacherGroups } from "../../http/teacherAPI";
import { observer } from "mobx-react-lite";

const Teacher = observer(() => {
    const { groups } = useContext(Context);

    useEffect(() => {
        getTeacherGroups().then(data => {
            // Обновляем состояние групп с данными из базы данных
            groups.setGroup(data);

            // Для каждой группы загружаем количество уроков
            data.forEach(group => {
                getLessonsCount(group.id).then(lessonsData => {
                    // Обновляем количество уроков для каждой группы
                    const updatedGroups = groups.allGroups.map(g =>
                        g.id === group.id ? { ...g, lessonsCount: lessonsData.lessonsCount } : g
                    );
                    groups.setGroup(updatedGroups);
                }).catch(error => {
                    console.error('Ошибка при загрузке количества уроков:', error);
                });
            });
        });
    }, [groups]);

    // Функция для обновления количества уроков
    const updateLessonsCount = (groupId, newLessonsCount) => {
        const updatedGroups = groups.allGroups.map(group => {
            if (group.id === groupId) {
                return { ...group, lessonsCount: newLessonsCount };
            }
            return group;
        });
        groups.setGroup(updatedGroups);
    }

    return (
        <div className="lg:mx-0 lg:my-5">
            <div className="text-3xl lg:text-5xl font-medium mb-5">Список групп</div>
            {groups.allGroups.length > 0 ? (
                groups.allGroups.map((group, index) => (
                <div key={index} className="lg:flex lg:justify-between items-center border border-neutral-content p-5 rounded-3xl mt-5">
                    <div>
                        <h3 className="text-xl lg:text-3xl mb-5">{group.name}</h3>
                        <div className="flex items-center">
                            <div className="badge badge-neutral p-5">Урок {group.lessonsCount}</div>
                            <GroupModal updateLessonsCount={updateLessonsCount} students={group.Students} groupName={group.name} courseId={group.courseId} groupId={group.id} />
                        </div>
                    </div>
                    <div className="avatar-group -space-x-6 rtl:space-x-reverse h-16 mt-5 lg:mt-0">
                        {group.Students.map((student, studentIndex) => (
                            <div key={studentIndex} className="avatar">
                                <div className="">
                                    {student.avatar ? (
                                        <img src={process.env.REACT_APP_API_URL + 'profiles/' + student.avatar} />
                                    ) : (
                                        <div className="bg-neutral text-neutral-content rounded-3xl h-full flex items-center justify-center">
                                            <span className="text-3xl">{student.name[0]}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                ))
            ) : (
                <div className="card w-full bg-neutral mb-5 text-neutral-content">
                    <div className="card-body p-5">
                        <h2 className="card-title">У вас нет активных групп</h2>
                        <p>Узнать какие есть свободные группы можно у администратора</p>
                    </div>
                </div>
            )}
        </div>
    );
});

export default Teacher;
