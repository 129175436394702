import { makeAutoObservable } from "mobx";

class ModuleProgressStore {
    moduleProgress = {};

    constructor() {
        makeAutoObservable(this);
    }

    setModuleProgress(progress) {
        progress.forEach(item => {
            this.moduleProgress[item.moduleId] = item;
        });
    }

    isModuleOpen(moduleId) {
        return this.moduleProgress[moduleId]?.isOpened || false;
    }

    isModuleProgressLoaded(moduleId) {
        return this.moduleProgress.hasOwnProperty(moduleId);
    }
}

export default new ModuleProgressStore();
