import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserStore from "./store/UserStore";
import CourseStore from "./store/CourseStore";
import PassedCourseStore from "./store/PassedCourseStore";
import ModuleStore from "./store/ModuleStore";
import AchievementsStore from "./store/AchievementsStore";
import TaskStore from "./store/TaskStore";
import GroupStore from "./store/GroupStore";
import ModuleProgressStore from "./store/ModuleProgressStore";

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Context.Provider value={{
          user: new UserStore(),
          course: new CourseStore(),
          passedCourse: new PassedCourseStore(),
          modules: new ModuleStore(),
          achievements: new AchievementsStore(),
          tasks: new TaskStore(),
          groups: new GroupStore(),
          moduleProgressStore: ModuleProgressStore,
      }}>
          <App />
      </Context.Provider>
  </React.StrictMode>

);

