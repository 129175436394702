import {makeAutoObservable} from "mobx";

export default class GroupStore {
    constructor() {
        this._groups = []
        makeAutoObservable(this)
    }

    setGroup(groups) {
        this._groups = groups
    }

    get allGroups() {
        return this._groups
    }
}