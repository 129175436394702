import React, {useContext, useEffect} from 'react';
import {Context} from "../index";
import {getAchievementUser} from "../http/courseAPI";

const HomeAchievements = () => {
    const {achievements} = useContext(Context)

    useEffect(() => {
        getAchievementUser().then(data => achievements.setCourse(data))
    }, []);

    return (
        <div className="flex gap-5 overflow-x-scroll scrollbar w-[87vw] md:w-[100%]">
            {achievements.currentAchievements.map(AchieveValue =>
                <div
                    className="p-5 text-white text-center flex flex-col items-center rounded-3xl bg-center bg-cover w-64"
                    style={{backgroundImage: `url(${process.env.REACT_APP_API_URL + 'achievements/' + AchieveValue.background})`}}
                    key={AchieveValue.id}
                >
                    <div className="relative mb-2">
                        <div className="rounded-full p-3 h-16 w-16 bg-white opacity-50">

                        </div>
                        <img className="opacity-100 absolute inset-0 rounded-full" src={process.env.REACT_APP_API_URL + 'achievements/' + AchieveValue.logo} alt=""/>
                    </div>

                    <div className="text-xl font-semibold mb-2">{AchieveValue.title}</div>
                    <div className="w-64">{AchieveValue.description}</div>
                </div>
            )}
        </div>
    );
};

export default HomeAchievements;
