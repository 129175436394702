import React from 'react';
import AddCourse from "./methodistModals/AddCourse";
import AddModule from "./methodistModals/AddModule";
import AddTask from "./methodistModals/AddTask";
import AddAchievement from "./methodistModals/AddAchievement";
import {useNavigate} from "react-router-dom";
import {ADD_TASK_INFO} from "../../utils/consts";

const Methodist = () => {
    const navigate = useNavigate()

    const openPage = () => {
        navigate(ADD_TASK_INFO)
    }
    return (
        <div className="h-[83vh] md:my-5 md:mx-0 md:h-fit overflow-scroll md:grid md:grid-cols-2 md:gap-5">
            <div
                className="p-5 rounded-2xl h-64 flex flex-col justify-between"
                style={{backgroundImage: `url(https://codybara.ru/static/admin/add_course.png)`}}>
                <div className="font-semibold text-3xl text-white">Добавить новый курс</div>
                <AddCourse />

            </div>

            <div className="p-5 rounded-2xl h-64 flex flex-col justify-between bg-gradient my-5 md:my-0">
                <div className="font-semibold text-3xl text-white">Добавить новый модуль</div>
                <AddModule />
            </div>

            <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                <div className="font-semibold text-3xl">Добавить новое задание</div>
                <AddTask />
            </div>

            <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                <div className="font-semibold text-3xl">Добавить инструкцию для задания</div>
                <div className="btn btn-outline w-fit" onClick={openPage}>Добавить</div>
            </div>

            <div
                className="p-5 rounded-2xl h-64 flex flex-col justify-between"
                style={{backgroundImage: `url(https://codybara.ru/static/admin/add_achive.png)`}}>
                <div className="font-semibold text-3xl text-white">Добавить новое достижение</div>
                <AddAchievement />
            </div>
        </div>
    );
};

export default Methodist;