import React, {useContext, useEffect, useRef, useState} from 'react';
import {Context} from "../index";
import {useLocation, useNavigate} from "react-router-dom";
import {LOGIN_ROUTE} from "../utils/consts";
import {LuImage} from "react-icons/lu";
import {changeAvatar, changeInfo, getUser} from "../http/userAPI";
import {observer} from "mobx-react-lite";
import { Store } from 'react-notifications-component';


const NavBar = observer(() => {
    const {user} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const modalRef = useRef(null);
    const modalRefThemes = useRef(null);
    const [currentTheme, setCurrentTheme] = useState('default');
    const [avatar, setAvatar] = useState(null);
    const [info, setInfo] = useState('')


    useEffect(() => {
        const savedTheme = localStorage.getItem('currentTheme');
        if (savedTheme) {
            setCurrentTheme(savedTheme);
            document.documentElement.setAttribute('data-theme', savedTheme);
        }
    }, []);
    const notify = (title, message, type) => {
        Store.addNotification({
            title,
            message,
            type,
            insert: "top",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    };

    const generateRandomName = () => {
        const letters = 'abcdefghijklmnopqrstuvwxyz';
        let randomName = '';
        for (let i = 0; i < 10; i++) {
            randomName += letters.charAt(Math.floor(Math.random() * letters.length));
        }
        return randomName;
    };

    const handleFileChange = (e) => {
        try {
            const file = e.target.files[0];
            if (!file) {
                return; // Если файл не выбран, ничего не делаем
            }

            // Проверка размера файла (2МБ = 2 * 1024 * 1024 байт)
            if (file.size > 2 * 1024 * 1024) {
                notify('Ошибка', 'Размер файла не должен превышать 2 МБ', 'danger');
                modalRef.current.close();
                return;
            }

            // Проверка формата файла (только jpg, png и gif)
            if (!file.type.match('image/jpeg') && !file.type.match('image/png') && !file.type.match('image/gif')) {
                notify('Ошибка', 'Допустимы только файлы с расширением .jpg, .png или .gif', 'danger');
                modalRef.current.close();
                return;
            }

            const randomFileName = generateRandomName() + '_' + file.name;
            const newFile = new File([file], randomFileName, { type: file.type });
            setAvatar(newFile);
        } catch (error) {
            notify('Ошибка', 'Допустимы только файлы с расширением .jpg, .png или .gif и не больше 2МБ', 'danger');
            modalRef.current.close();
        }

    };

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        navigate(LOGIN_ROUTE)
    }

    const changeUserInfo = async (userID) => {
        if (info.trim() !== '') { // Проверка на пустоту
            try {
                // Если аватар был выбран, то обновляем аватар
                if (avatar) {
                    await changeAvatar(userID, avatar);
                    const data = user.user;
                    data.avatar = avatar.name; // Обновить аватар в состоянии
                    user.setUser(data);
                }

                // Обновление информации пользователя
                await changeInfo(userID, info);
                const data = user.user;
                data.info = info; // Обновить информацию в состоянии
                user.setUser(data);

                modalRef.current.close();
            } catch (error) {
                console.error("Ошибка при обновлении информации:", error);
                notify('Ошибка', 'Ошибка при обновлении информации', 'danger');
                modalRef.current.close();
            }
        } else {
            notify('Ошибка', 'Поле информации пустое. Напишите что-то о себе.', 'danger');
            modalRef.current.close();
        }
    }

    const handleThemeChange = (newTheme) => {
        setCurrentTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
        localStorage.setItem('currentTheme', newTheme);
    };

    return (
        <div className="navbar bg-base-100 border-b border-primary py-[1.6] dark">
            <div className="flex-1">

            </div>
            <div className="flex-none">
                <div className="dropdown dropdown-end border-l border-primary pl-2">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                        <div className="w-10 rounded-full">
                            {user.user.avatar ? (
                                <img className="h-[100px] rounded-3xl"
                                     src={process.env.REACT_APP_API_URL + 'profiles/' + user.user.avatar} alt=""/>
                            ) : (
                                <div
                                    className="bg-neutral text-neutral-content rounded-full h-full flex items-center justify-center">
                                    <span className="text-2xl">{user.user.name ? user.user.name[0] : ''}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <ul tabIndex={0}
                        className="menu menu-sm dropdown-content mt-3 z-[999] p-2 shadow bg-base-100 rounded-box w-52">
                        <li>
                            <a className="justify-between"
                               onClick={() => modalRef.current && modalRef.current.showModal()}>
                                Профиль
                            </a>
                        </li>
                        <li>
                            <a className="justify-between"
                               onClick={() => modalRefThemes.current && modalRefThemes.current.showModal()}>
                                Изменить тему
                            </a>
                        </li>
                        <li>
                            <button onClick={() => logOut()}>Выйти</button>
                        </li>
                    </ul>
                </div>
                <div className="ml-1">{user.user.name}</div>
            </div>
            <dialog ref={modalRef} id="modal_profile" className="modal">
                <div className="modal-box">
                    <div className="">
                        <div className="flex flex-col items-center md:flex-row">
                            <div
                                className="bg-neutral p-2.5 rounded-full mb-2.5 md:mr-2.5 h-24 w-24 flex items-center justify-center">
                                {avatar ? null : <LuImage className={"text-neutral-content"}/>}
                                {avatar &&
                                    <img className="rounded-full" src={URL.createObjectURL(avatar)} alt="Avatar"/>}
                            </div>
                            <input type="file" className="file-input w-full max-w-xs mb-2.5"
                                   onChange={handleFileChange}/>
                        </div>
                        <div className="mt-2.5">
                            {info.trim() !== '' ? (
                                <span className="label-text">Описание профиля</span>
                            ) : (
                                <span className="label-text">Описание профиля не может быть пустым!</span>
                            )}
                            <textarea
                                className="textarea textarea-bordered w-full my-2.5"
                                placeholder={user.user.info !== null ? user.user.info : "Новое описание профиля"}
                                maxLength="200"
                                value={info}
                                onChange={e => setInfo(e.target.value)}
                            ></textarea>
                        </div>

                        {/*  ограничение 255 символов  */}
                    </div>
                    <div className="flex items-center justify-end">
                        <kbd className="kbd mr-5">ESC</kbd>
                        <button className="btn btn-primary" onClick={() => changeUserInfo(user.user.id)}>Подтвердить
                        </button>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>

            <dialog ref={modalRefThemes} id="modal_themes" className="modal">
                <div className="modal-box">
                    <div className="join join-vertical w-full mb-5">
                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Default"
                            value="nord"
                            aria-checked={currentTheme === 'nord'}
                            onChange={() => {
                                handleThemeChange('nord');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />
                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Light"
                            value="light"
                            aria-checked={currentTheme === 'light'}
                            onChange={() => {
                                handleThemeChange('light');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />
                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Dark"
                            value="Dark"
                            aria-checked={currentTheme === 'Dark'}
                            onChange={() => {
                                handleThemeChange('Dark');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />
                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Cupcake"
                            value="cupcake"
                            aria-checked={currentTheme === 'cupcake'}
                            onChange={() => {
                                handleThemeChange('cupcake');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />
                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Bumbleblee"
                            value="bumblebee"
                            aria-checked={currentTheme === 'bumblebee'}
                            onChange={() => {
                                handleThemeChange('bumblebee');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />
                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Emerald"
                            value="emerald"
                            aria-checked={currentTheme === 'emerald'}
                            onChange={() => {
                                handleThemeChange('emerald');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Corporate"
                            value="corporate"
                            aria-checked={currentTheme === 'corporate'}
                            onChange={() => {
                                handleThemeChange('corporate');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Synthwave"
                            value="synthwave"
                            aria-checked={currentTheme === 'synthwave'}
                            onChange={() => {
                                handleThemeChange('synthwave');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Retro"
                            value="retro"
                            aria-checked={currentTheme === 'retro'}
                            onChange={() => {
                                handleThemeChange('retro');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Cyberpunk"
                            value="cyberpunk"
                            aria-checked={currentTheme === 'cyberpunk'}
                            onChange={() => {
                                handleThemeChange('cyberpunk');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Valentine"
                            value="valentine"
                            aria-checked={currentTheme === 'valentine'}
                            onChange={() => {
                                handleThemeChange('valentine');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Halloween"
                            value="halloween"
                            aria-checked={currentTheme === 'halloween'}
                            onChange={() => {
                                handleThemeChange('halloween');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Garden"
                            value="garden"
                            aria-checked={currentTheme === 'garden'}
                            onChange={() => {
                                handleThemeChange('garden');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Forest"
                            value="forest"
                            aria-checked={currentTheme === 'forest'}
                            onChange={() => {
                                handleThemeChange('forest');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Aqua"
                            value="aqua"
                            aria-checked={currentTheme === 'aqua'}
                            onChange={() => {
                                handleThemeChange('aqua');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Pastel"
                            value="pastel"
                            aria-checked={currentTheme === 'pastel'}
                            onChange={() => {
                                handleThemeChange('pastel');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Fantasy"
                            value="fantasy"
                            aria-checked={currentTheme === 'fantasy'}
                            onChange={() => {
                                handleThemeChange('fantasy');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Wireframe"
                            value="wireframe"
                            aria-checked={currentTheme === 'wireframe'}
                            onChange={() => {
                                handleThemeChange('wireframe');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Black"
                            value="black"
                            aria-checked={currentTheme === 'black'}
                            onChange={() => {
                                handleThemeChange('black');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Luxury"
                            value="luxury"
                            aria-checked={currentTheme === 'luxury'}
                            onChange={() => {
                                handleThemeChange('luxury');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Dracula"
                            value="dracula"
                            aria-checked={currentTheme === 'dracula'}
                            onChange={() => {
                                handleThemeChange('dracula');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Cmyk"
                            value="cmyk"
                            aria-checked={currentTheme === 'cmyk'}
                            onChange={() => {
                                handleThemeChange('cmyk');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Autumn"
                            value="autumn"
                            aria-checked={currentTheme === 'autumn'}
                            onChange={() => {
                                handleThemeChange('autumn');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Business"
                            value="business"
                            aria-checked={currentTheme === 'business'}
                            onChange={() => {
                                handleThemeChange('business');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Acid"
                            value="acid"
                            aria-checked={currentTheme === 'acid'}
                            onChange={() => {
                                handleThemeChange('acid');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Lemonade"
                            value="lemonade"
                            aria-checked={currentTheme === 'lemonade'}
                            onChange={() => {
                                handleThemeChange('lemonade');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Night"
                            value="night"
                            aria-checked={currentTheme === 'night'}
                            onChange={() => {
                                handleThemeChange('night');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Coffee"
                            value="coffee"
                            aria-checked={currentTheme === 'coffee'}
                            onChange={() => {
                                handleThemeChange('coffee');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Winter"
                            value="winter"
                            aria-checked={currentTheme === 'winter'}
                            onChange={() => {
                                handleThemeChange('winter');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Dim"
                            value="dim"
                            aria-checked={currentTheme === 'dim'}
                            onChange={() => {
                                handleThemeChange('dim');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Nord"
                            value="nord"
                            aria-checked={currentTheme === 'nord'}
                            onChange={() => {
                                handleThemeChange('nord');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />

                        <input
                            type="radio"
                            name="theme-buttons"
                            className="btn theme-controller join-item"
                            aria-label="Sunset"
                            value="sunset"
                            aria-checked={currentTheme === 'sunset'}
                            onChange={() => {
                                handleThemeChange('sunset');
                                modalRefThemes.current.close(); // Закрыть модальное окно после выбора темы
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-end">
                        <kbd className="kbd mr-5">ESC</kbd>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    );
});

export default NavBar;