import React, {useContext, useEffect, useState} from 'react';
import Methodist from "../components/admin/Methodist";
import NavBar from "../components/NavBar";
import RootLayout from "../components/RootLayout";
import {Context} from "../index";
import {getUser} from "../http/userAPI";
import BlockUser from "../components/BlockUser";
import {ReactNotifications} from "react-notifications-component";

const MethodistPage = () => {
    const {user} = useContext(Context)
    const [isMethodist, setIsMethodist] = useState(false);

    useEffect(() => {
        getUser().then(data => {
            user.setUser(data);
            setIsMethodist(data.role === 'METHODIST' || data.role === "ADMIN");
        });
    }, [user.setUser]);


    return (
        <RootLayout>
            <ReactNotifications />
            <NavBar />
            <div className="mt-5 mx-5 h-[89vh] overflow-scroll lg:mx-0">
                { isMethodist  ? <Methodist /> : <BlockUser/> }
            </div>
        </RootLayout>
    );
};

export default MethodistPage;