import React, {useContext, useState} from 'react';
import {changeRole} from "../../../http/userAPI";
import { Store } from 'react-notifications-component'
import {Context} from "../../../index";

const ChangeUserRole = () => {
    const {user} = useContext(Context)
    const [userID, setUserID] = useState(0)
    const [role, setRole] = useState('')

    const handleRoleChange = async (e) => {
        e.preventDefault()
        try {
            if (user.user.role === 'ADMIN' || user.user.role === "ADMINISTRATOR") {
                if (user.user.role === 'ADMINISTRATOR' && role === 'ADMIN') {
                    Store.addNotification({
                        title: "Упс, что-то пошло не так!",
                        message: "Роль пользователя не изменена, вы не можете добавлять роль ADMIN",
                        type: "danger",
                        insert: "top",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                    document.getElementById('modal_change_role').close();
                } else {
                    await changeRole(user.user.role, userID, role);
                    Store.addNotification({
                        title: "Получилось!",
                        message: "Роль пользователя успешно изменена",
                        type: "success",
                        insert: "top",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                    document.getElementById('modal_change_role').close();
                }
            } else {
                alert('Увы, но вы не администратор. Если это ошибка, свяжитесь с разработчиками')
            }

        } catch (error) {
            Store.addNotification({
                title: "Упс, что-то пошло не так!",
                message: "Роль пользователя не изменена, свяжитесь с поддержкой",
                type: "danger",
                insert: "top",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            document.getElementById('modal_change_role').close();
        }
    }

    return (
        <div>
            <button
                className="btn btn-outline w-fit text-accent"
                onClick={()=>document.getElementById('modal_change_role').showModal()}
            >Изменить</button>
            <dialog id="modal_change_role" className="modal">
                <div className="modal-box">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="font-bold text-2xl text-accent">Изменение роли у пользователя</h3>
                        <kbd className="kbd">ESC</kbd>
                    </div>
                    <hr/>
                    <form className="flex flex-col mt-5" onSubmit={handleRoleChange}>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите id пользователя</span>
                            </div>
                            <input
                                type="number"
                                placeholder="Количество уроков на курсе..."
                                className="input input-bordered w-full"
                                value={userID}
                                onChange={e => setUserID(e.target.value)}
                            />
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-lg">Введите новую роль</span>
                            </div>
                            <input
                                placeholder="USER, METHODIST, TEACHER, ADMINISTRATOR"
                                className="input input-bordered w-full"
                                value={role}
                                onChange={e => setRole(e.target.value)}
                            />
                        </label>

                        <button className="btn btn-primary mt-5" type="submit">Изменить роль пользователя</button>
                    </form>

                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    );
};

export default ChangeUserRole;