import React from 'react';
import AddStudent from "./administrator/AddStudent";
import ChangeUserRole from "./administrator/ChangeUserRole";
import DeleteUser from "./administrator/DeleteUser";
import RemoveStudent from "./administrator/RemoveStudent";
import AddStudentPassedCourse from "./administrator/AddStudentPassedCourse";
import AddAchievementUser from "./administrator/AddAchievementUser";
import RemoveAchievementUser from "./administrator/RemoveAchievementUser";
import AddGroup from "./administrator/AddGroup";
import AddUserToGroup from "./administrator/AddUserToGroup";
import EditLessonsCount from "./administrator/EditLessonsCount";
import EditTeacherInGroup from "./administrator/EditTeacherInGroup";
import RemoveUserFromGroup from "./administrator/RemoveUserFromGroup";

const Administrator = () => {
    return (
        <div>
            <div className="text-2xl border-b pb-2.5 text-accent border-accent">Пользователи</div>

            <div className="md:mt-5 md:mx-0 md:h-fit overflow-scroll md:grid md:grid-cols-2 md:gap-5">
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Добавить ученика на курс</div>
                    <AddStudent />
                </div>
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Добавить ученику прошедший курс</div>
                    <AddStudentPassedCourse />
                </div>

                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Убрать курс у ученика</div>
                    <RemoveStudent />
                </div>
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Добавить достижение ученику</div>
                    <AddAchievementUser />
                </div>
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Удалить достижение ученику</div>
                    <RemoveAchievementUser />
                </div>
            </div>

            <div className="text-2xl border-b pb-2.5 text-slate-400 mt-5">Группы</div>
            <div className="md:mt-5 md:mx-0 md:h-fit overflow-scroll md:grid md:grid-cols-2 md:gap-5">
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Создать новую группу</div>
                    <AddGroup />
                </div>
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Добавить ученика в группу</div>
                    <AddUserToGroup />
                </div>
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Удалить ученика из группы</div>
                    <RemoveUserFromGroup />
                </div>
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Изменить количество проведённых уроков у группы</div>
                    <EditLessonsCount/>
                </div>
                <div className="p-5 rounded-2xl h-64 flex flex-col justify-between mb-5 md:mb-0 text-inherit border border-accent">
                    <div className="font-semibold text-3xl text-accent">Замена учителя в группе</div>
                    <EditTeacherInGroup/>
                </div>
            </div>
        </div>
    );
};

export default Administrator;