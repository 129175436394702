import React from 'react';
import { useNavigate } from "react-router-dom";
import {COURSE_ROUTE} from "../utils/consts";

const CourseList = ({ courseList, title }) => {
    const navigate = useNavigate()

    function navigateToCourse(courseId) {
        navigate(`${COURSE_ROUTE}/${courseId}`)
    }

    return (
        <div>
            <div className="px-5 xl:px-0 overflow-x-hidden overflow-y-scroll pb-5 scrollbar-none">
                <div className="mt-5 text-4xl lg:text-5xl font-semibold">{title}</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
                    {courseList.map(courseValue =>
                        <div
                           className="p-4 text-white rounded-lg flex flex-col justify-between bg-auto bg-center h-[50vh] hover:cursor-pointer"
                           style={{backgroundImage: `url(${process.env.REACT_APP_API_URL + courseValue.background})`}}
                           key={courseValue.id}
                           onClick={() => navigateToCourse(courseValue.id)}
                        >
                            <div className="flex flex-col">
                                <div className="text-neutral-content">{courseValue.tags}</div>
                                <div className="text-2xl md:text-3xl lg:text-4xl my-4">{courseValue.title}</div>
                            </div>

                            <div className="badge badge-outline p-5 text-neutral-content">{courseValue.duration}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CourseList;