import {$authHost, $host} from "./index";

export const getTeacherGroups = async () => {
    try {
        const { data } = await $authHost.get('api/teacher/getGroups');
        return data;
    } catch (error) {
        console.log('Произошла ошибка при загрузке групп:');
        console.log(error);
        throw error;
    }
};

export const approveModule = async (moduleId, userId, isCompleted, courseId) => {
    try {
        const response = await $authHost.post('api/teacher/completeModule', {
            moduleId,
            userId,
            isCompleted,
            courseId
        });
        return response.data;
    } catch (error) {
        console.error('Произошла ошибка при подтверждении модуля:', error);
        throw error;
    }
};

export const getStudentModuleStatus = async (groupId) => {
    try {
        const { data } = await $authHost.get(`api/teacher/getStudentModuleStatus?groupId=${groupId}`);
        return data;
    } catch (error) {
        console.log('Произошла ошибка при получении статуса модулей учеников:', error);
        throw error;
    }
};

export const incrementLesson = async (groupId) => {
    const { data } = await $authHost.post('api/teacher/incrementLesson', { groupId });
    return data;
};

export const getLessonsCount = async (groupId) => {
    try {
        const response = await $authHost.get('api/teacher/getLessonsCount', {
            params: { groupId }
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при получении количества уроков:', error);
        throw error;
    }
};