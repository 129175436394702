import axios from "axios";
import { LOGIN_ROUTE } from "../utils/consts";

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
};

$authHost.interceptors.request.use(authInterceptor);

$authHost.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            window.location = LOGIN_ROUTE;
        }
        return Promise.reject(error);
    }
);

export {
    $host,
    $authHost
};
